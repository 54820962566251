import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  restaurantInfo: {},
  isLoadingRestaurantInfo: false,
  errorResponse: null
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RESTAURANT_INFO_START:
      return {
        ...state,
        isLoadingRestaurantInfo: true
      }
    case actionTypes.GET_RESTAURANT_INFO_SUCCESS:
      return {
        ...state,
        isLoadingRestaurantInfo: false,
        restaurantInfo: action.data[0],
        errorResponse: null
      }
    case actionTypes.GET_RESTAURANT_INFO_FAIL:
      return {
        ...state,
        isLoadingRestaurantInfo: false,
        errorResponse: action.error
      }
    case actionTypes.UPDATE_RESTAURANT_INFO_START:
      return {
        ...state,
        isLoadingRestaurantInfo: true
      }
    case actionTypes.UPDATE_RESTAURANT_INFO_SUCCESS:
      return {
        ...state,
        isLoadingRestaurantInfo: false,
        restaurantInfo: action.data,
        errorResponse: null
      }
    case actionTypes.UPDATE_RESTAURANT_INFO_FAIL:
      return {
        ...state,
        isLoadingRestaurantInfo: false,
        errorResponse: action.error
      }
    default:
      return state;
  }
}

export default reducer;