import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';
import * as actions from '../../../state/actions/index';
import Loading from '../../UI/Loading/Loading';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledTextarea = styled.textarea`
  background-color: #16283f;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const StyledInput = styled.input`
  background-color: #16283f;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const SettingInfoForm = (props) => {
  const { 
    restaurantInfo, 
    updateRestaurantInfo, 
    getRestaurantInfo, 
    errorResponse,
    activeRestaurant
   } = props;

  const { register, reset, errors, handleSubmit } = useForm({
    defaultValues: {
      desc: restaurantInfo.desc,
      localization: restaurantInfo.localization,
      hours: restaurantInfo.hours,
      deliverymin: restaurantInfo.deliverymin,
      deliveryprice: restaurantInfo.deliveryprice,
      packageprice: restaurantInfo.packageprice,
      phone: restaurantInfo.phone,
      email: restaurantInfo.email
    }}
  );

  useEffect(() => {
    getRestaurantInfo(window.localStorage.getItem('activeRestaurant'));
  }, [activeRestaurant]);

  useEffect(() => {
    reset(restaurantInfo);
  }, [restaurantInfo]);

  const onSubmit = data => {
    updateRestaurantInfo(restaurantInfo._id, data.desc, data.localization, data.hours, data.deliverymin, data.deliveryprice, data.packageprice, data.phone, data.email);
  }

  return (
    Object.keys(restaurantInfo).length > 0
    ?
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <InputField>
          <StyledTextarea
            name="desc"
            placeholder="Opis Lokalu"
            ref={register({
              required: {
                value: true,
                message: 'Opis jest wymagany'
              }
            })}
          />
          <span>{errors.desc && <StyledAlertIcon />}</span>
          <div>{errors.desc && errors.desc.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name="localization"
            placeholder="Lokalizacja / Adres Restauracji"
            ref={register(
              { value: "data" },
              {
              required: {
              value: true,
              message: 'Lokalizacja jest wymagana'
              }
            })}
          />
          <span>{errors.localization && <StyledAlertIcon />}</span>
          <div>{errors.localization && errors.localization.message}</div>
        </InputField>
        <InputField>
          <StyledTextarea
            name="hours"
            placeholder="Godziny Otwarcia"
            ref={register({
              required: {
                value: true,
                message: 'Godziny otwarcia są wymagane'
              }
            })}
          />
          <span>{errors.hours && <StyledAlertIcon />}</span>
          <div>{errors.hours && errors.hours.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name="deliverymin"
            placeholder="Minimum logistyczne"
            ref={register({
              required: {
                value: true,
                message: 'Minimum logistyczne jest wymagane'
              }
            })}
          />
          <span>{errors.deliverymin && <StyledAlertIcon />}</span>
          <div>{errors.deliverymin && errors.deliverymin.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name="deliveryprice"
            placeholder="Zakres ceny dostaw"
            ref={register({
              required: {
                value: true,
                message: 'Ceny dostaw są wymagane'
              }
            })}
          />
          <span>{errors.deliveryprice && <StyledAlertIcon />}</span>
          <div>{errors.deliveryprice && errors.deliveryprice.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name="packageprice"
            placeholder="Cena opakowania"
            ref={register({
              required: {
                value: true,
                message: 'Cena opakowania jest wymagana'
              }
            })}
          />
          <span>{errors.packageprice && <StyledAlertIcon />}</span>
          <div>{errors.packageprice && errors.packageprice.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name="phone"
            placeholder="Telefon kontaktowy"
            ref={register({
              required: {
                value: true,
                message: 'Telefon kontaktowy jest wymagany'
              }
            })}
          />
          <span>{errors.phone && <StyledAlertIcon />}</span>
          <div>{errors.phone && errors.phone.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name="email"
            placeholder="Adres email"
            ref={register({
              required: {
                value: true,
                message: 'Adres email jest wymagany'
              }
            })}
          />
          <span>{errors.email && <StyledAlertIcon />}</span>
          <div>{errors.email && errors.email.message}</div>
        </InputField>

        <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
        <StyledInput type='submit' value='Zapisz Informacje' />
      </StyledForm>
    </>
    :
    <Loading />
  );
};

const mapStateToProps = state => ({
  restaurantInfo: state.restaurantInfo.restaurantInfo,
  errorResponse: state.restaurantInfo.errorResponse,
  activeRestaurant: state.restaurants.activeRestaurant,
});

const mapDispatchToProps = dispatch => ({
  updateRestaurantInfo: (restaurantInfoId, desc, localization, hours, deliverymin, deliveryprice, packageprice, phone, email) => dispatch(actions.updateRestaurantInfo(restaurantInfoId, desc, localization, hours, deliverymin, deliveryprice, packageprice, phone, email)),
  getRestaurantInfo: (restaurantId) => dispatch(actions.getRestaurantInfo(restaurantId))
});


SettingInfoForm.propTypes = {
  updateRestaurantInfo: PropTypes.func.isRequired,
  getRestaurantInfo: PropTypes.func.isRequired,
  errorResponse: PropTypes.string,
  restaurantInfo: PropTypes.shape({
    _id: PropTypes.string,
    desc: PropTypes.string,
    localization: PropTypes.string,
    hours: PropTypes.string,
    deliverymin: PropTypes.string,
    deliveryprice: PropTypes.string,
    packageprice: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  activeRestaurant: PropTypes.shape({
    _id: PropTypes.string
  }),
};

SettingInfoForm.defaultProps = {
  errorResponse: null,
  restaurantInfo: {},
  activeRestaurant: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingInfoForm);
