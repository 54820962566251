import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PlusCircle } from 'react-feather';
import styled from 'styled-components';
import * as actions from '../../../state/actions/index';
import Modal from '../../../components/UI/Modal/Modal';
import useModal from '../../../hooks/useModal/useModal';
import ItemCategoriesList from './ItemCategoriesList/ItemCategoriesList';

import Loading from '../../../components/UI/Loading/Loading';
import Button from '../../../components/UI/Button/Button';
import AddMenuCategoryForm from '../../../components/Forms/AddMenuCategoryForm/AddMenuCategoryForm';

const MenuTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.h1`
  margin-right: 20px;
`;

const PlusCircleIcon = styled(PlusCircle)`
  margin-right: 15px;
`;

const Menu = (props) => {
  const {
    getImages,
    getItemCategories,
    getMenuItems,
    getItemsOptionGroups,
    getItemOptions,
    isLoadingImages,
    isLoadingItemCategories,
    isLoadingItemsOptionGroups,
    isLoadingItems,
    isLoadingItemOptions
  } = props;

  const [isShowingModal, toggleModal] = useModal();

  useEffect(() => {
    getImages();
    getMenuItems(window.localStorage.getItem('activeRestaurant'));
    getItemCategories(window.localStorage.getItem('activeRestaurant'));
    getItemsOptionGroups(window.localStorage.getItem('activeRestaurant'));
    getItemOptions(window.localStorage.getItem('activeRestaurant'));
  }, [])

  const handleAddCategory = () => {
    toggleModal();
  }

  return (
    <div>
      <MenuTitleContainer>
        <Title>Menu</Title>
        <Button clicked={handleAddCategory}><PlusCircleIcon /> Nowa Kategoria</Button>
      </MenuTitleContainer>

      {
        (!isLoadingImages && !isLoadingItemCategories && !isLoadingItemsOptionGroups && !isLoadingItems && !isLoadingItemOptions)
          ?
          <ItemCategoriesList />
          :
          <Loading />
      }

      <Modal
        show={isShowingModal}
        clicked={toggleModal}
      >
        <AddMenuCategoryForm />
      </Modal>
    </div>
  )
};

const mapStateToProps = state => ({
  isLoadingImages: state.images.isLoading,
  isLoadingItemCategories: state.itemCategories.isLoading,
  isLoadingItemsOptionGroups: state.itemsOptionGroups.isLoading,
  isLoadingItems: state.items.isLoading,
  isLoadingItemOptions: state.itemOptions.isLoading
});

const mapDispatchToProps = dispatch => ({
  getImages: () => dispatch(actions.getImages()),
  getItemCategories: (restaurantId) => dispatch(actions.getItemCategories(restaurantId)),
  getMenuItems: (restaurantId) => dispatch(actions.getMenuItems(restaurantId)),
  getItemsOptionGroups: (restaurantId) => dispatch(actions.getItemsOptionGroups(restaurantId)),
  getItemOptions: (restaurantId) => dispatch(actions.getItemOptions(restaurantId))
});

Menu.propTypes = {
  getImages: PropTypes.func.isRequired,
  getItemCategories: PropTypes.func.isRequired,
  getMenuItems: PropTypes.func.isRequired,
  getItemsOptionGroups: PropTypes.func.isRequired,
  getItemOptions: PropTypes.func.isRequired,
  isLoadingImages: PropTypes.bool.isRequired,
  isLoadingItemCategories: PropTypes.bool.isRequired,
  isLoadingItemsOptionGroups: PropTypes.bool.isRequired,
  isLoadingItems: PropTypes.bool.isRequired,
  isLoadingItemOptions: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
