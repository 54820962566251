import React, { useState, useMemo, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import styled from 'styled-components';
import Loading from '../../../../components/UI/Loading/Loading';
import CategoryOverlay from './CategoryOverlay';
import Modal from '../../../../components/UI/Modal/Modal';
import useModal from '../../../../hooks/useModal/useModal';
import * as actions from '../../../../state/actions/index';
import ConfirmationModal from '../../../../components/UI/ConfirmationModal/ConfirmationModal';
import useConfirmationModal from '../../../../hooks/UseConfirmationModal/useConfirmationModal';
import EditMenuCategoryForm from '../../../../components/Forms/EditMenuCategoryForm/EditMenuCategoryForm';
import ItemCategortiesListItem from './ItemCategoriesListItem';
import AddMenuItemForm from '../../../../components/Forms/AddMenuItemForm/AddMenuItemForm';



const ListContainer = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const ItemCategoriesList = (props) => {
  const {
    itemCategories,
    updateItemCategoriesOrder,
    deleteItemCategory
  } = props;

  const [isShowingConfirmationModal, toggleConfirmationModal] = useConfirmationModal();
  const [itemCategoryId, setItemCategoryId] = useState(null);
  const [isShowingModal, toggleModal] = useModal();
  const [isShowingAddItemModal, toggleAddItemModal] = useModal();
  const [toggledCategory, setToggledCategory] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [filteredItemCategories, setFilteredItemCategories] = useState(() => itemCategories.filter(item => itemCategoryId === item.itemCategory ? item : null).sort((a, b) => a.orderKey - b.orderKey));

  const handleClickedDelete = (id) => {
    toggleConfirmationModal();
    setItemCategoryId(id);
  }

  const handleConfirmDelete = (id) => {
    deleteItemCategory(id);
    toggleConfirmationModal();
  }

  const handleEditClicked = (id) => {
    toggleModal();
    setItemCategoryId(id);
  }

  const handleAddItemClicked = (id) => {
    toggleAddItemModal();
    setItemCategoryId(id);
  }

  const handleToggleCategory = (id) => {
    toggledCategory === id ? setToggledCategory(null) : setToggledCategory(id);
  }

  const handleDragStart = (event) => {
    const { active } = event;

    setActiveId(active.id);
  }

  useEffect(() => {
    setFilteredItemCategories(() => [...itemCategories].sort((a, b) => a.orderKey - b.orderKey))
  }, [itemCategories])

  const itemIds = useMemo(() => filteredItemCategories.map((item) => item._id), [filteredItemCategories]);


  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFilteredItemCategories((items) => {
        const oldIndex = items.map(e => e._id).indexOf(active.id);
        const newIndex = items.map(e => e._id).indexOf(over.id);

        const updatedOrder = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
          ...item,
          orderKey: index + 1
        }));
        updateItemCategoriesOrder(updatedOrder, window.localStorage.getItem('activeRestaurant'));
        return updatedOrder;
      })
    }
  }

  return (
    <ListContainer>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={itemIds}
          strategy={verticalListSortingStrategy}
        >
          {filteredItemCategories ?
            filteredItemCategories.map(itemCategory => (
              <ItemCategortiesListItem
                key={itemCategory._id}
                itemCategory={itemCategory}
                toggledCategory={toggledCategory}
                handleToggleCategory={() => handleToggleCategory(itemCategory._id)}
                handleEditClicked={() => handleEditClicked(itemCategory._id)}
                handleClickedDelete={() => handleClickedDelete(itemCategory._id)}
                handleAddItemClicked={() => handleAddItemClicked(itemCategory._id)}

              />
            ))
            :
            <Loading />
          }
        </SortableContext>
        <DragOverlay>
          {
            activeId
              ?
              (
                filteredItemCategories.filter((item) => item._id === activeId ? item : null)
                  .map((overlayItem) => (
                    <CategoryOverlay
                      key={activeId}
                      id={activeId}
                      name={overlayItem.name}
                      desc={overlayItem.desc}
                      image={overlayItem.image}
                      active={overlayItem.active}
                      price={overlayItem.price}
                      wrapping={overlayItem.wrapping}
                    />
                  ))
              )
              :
              null
          }
        </DragOverlay>
      </DndContext>
      <ConfirmationModal
        show={isShowingConfirmationModal}
        clicked={toggleConfirmationModal}
        title="Potwierdź operację"
        text="Czy chcesz usunąć kategorię"
        onConfirm={() => handleConfirmDelete(itemCategoryId)}
      />
      <Modal
        show={isShowingModal}
        clicked={toggleModal}
      >
        <EditMenuCategoryForm
          itemCategoryId={itemCategoryId}
        />
      </Modal>
      <Modal
        show={isShowingAddItemModal}
        clicked={toggleAddItemModal}
      >
        <AddMenuItemForm categoryId={itemCategoryId} />
      </Modal>
    </ListContainer>
  )
};


const mapStateToProps = state => ({
  itemCategories: state.itemCategories.itemCategories
});

const mapDispatchToProps = dispatch => ({
  updateItemCategoriesOrder: (itemCategoriesOrder, restaurantId) => dispatch(actions.updateItemCategoriesOrder(itemCategoriesOrder, restaurantId)),
  deleteItemCategory: (itemCategoryId) => dispatch(actions.deleteItemCategory(itemCategoryId))
});

ItemCategoriesList.propTypes = {
  deleteItemCategory: PropTypes.func.isRequired,
  updateItemCategoriesOrder: PropTypes.func.isRequired,
  itemCategories: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string
  }))
}

ItemCategoriesList.defaultProps = {
  itemCategories: []
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemCategoriesList);
