import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getDeliveryAreasStart = () => ({
  type: actionTypes.GET_DELIVERY_AREAS_START
});

export const getDeliveryAreasSuccess = (data) => ({
  type: actionTypes.GET_DELIVERY_AREAS_SUCCESS,
  data: data
});

export const getDeliveryAreasFail = (error) => ({
  type: actionTypes.GET_DELIVERY_AREAS_FAIL,
  error: error
});

export const getDeliveryAreas = (restaurantId) => dispatch => {
  dispatch(getDeliveryAreasStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/deliveryAreas/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getDeliveryAreasSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getDeliveryAreasFail(err.response.data.message));
    })
};

export const addDeliveryAreaStart = () => ({
  type: actionTypes.ADD_DELIVERY_AREA_START
});

export const addDeliveryAreaSuccess = (data) => ({
  type: actionTypes.ADD_DELIVERY_AREA_SUCCESS,
  data: data.deliveryArea
});

export const addDeliveryAreaFail = (error) => ({
  type: actionTypes.ADD_DELIVERY_AREA_FAIL,
  error: error
});

export const addDeliveryArea = (restaurantId) => dispatch => {
  dispatch(addDeliveryAreaStart());

  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/admin/deliveryArea`, {
    restaurantId
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(addDeliveryAreaSuccess(res.data));
    })
    .catch(err => {
      dispatch(addDeliveryAreaFail(err.response.data.message));
    })
};

export const updateAreaTypeStart = () => ({
  type: actionTypes.UPDATE_AREA_TYPE_START
});

export const updateAreaTypeSuccess = (data) => ({
  type: actionTypes.UPDATE_AREA_TYPE_SUCCESS,
  data: data.deliveryArea
});

export const updateAreaTypeFail = (error) => ({
  type: actionTypes.UPDATE_AREA_TYPE_FAIL,
  error: error
});

export const updateAreaType = (areaType, deliveryAreaId) => dispatch => {
  dispatch(updateAreaTypeStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/updateAreaType/${deliveryAreaId}`, {
    areaType
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(updateAreaTypeSuccess(res.data));
    })
    .catch(err => {
      dispatch(updateAreaTypeFail(err.response.data.message));
    })
};

export const addCircleZoneStart = () => ({
  type: actionTypes.ADD_CIRCLE_ZONE_START
});

export const addCircleZoneSuccess = (data) => ({
  type: actionTypes.ADD_CIRCLE_ZONE_SUCCESS,
  data: data.deliveryArea
});

export const addCircleZoneFail = (error) => ({
  type: actionTypes.ADD_CIRCLE_ZONE_FAIL,
  error: error
});

export const addCircleZone = (deliveryAreaId, radius, price) => dispatch => {
  dispatch(addCircleZoneStart());

  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/admin/addCircleArea`, {
    deliveryAreaId,
    radius,
    price
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(addCircleZoneSuccess(res.data));
    })
    .catch(err => {
      dispatch(addCircleZoneFail(err.response.data.message));
    })
};

export const deleteDeliveryAreaItemStart = () => ({
  type: actionTypes.DELETE_DELIVERY_AREA_ITEM_START
});

export const deleteDeliveryAreaItemSuccess = (data) => ({
  type: actionTypes.DELETE_DELIVERY_AREA_ITEM_SUCCESS,
  data: data.deliveryArea
});

export const deleteDeliveryAreaItemFail = (error) => ({
  type: actionTypes.DELETE_DELIVERY_AREA_ITEM_FAIL,
  error: error
});

export const deleteDeliveryAreaItem = (deliveryAreaItemId) => dispatch => {
  dispatch(deleteDeliveryAreaItemStart());

  const token = localStorage.getItem('token');
  axios.delete(`${process.env.REACT_APP_API}/admin/deleteDeliveryAreaItem/${deliveryAreaItemId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(deleteDeliveryAreaItemSuccess(res.data));
    })
    .catch(err => {
      dispatch(deleteDeliveryAreaItemFail(err.response.data.message));
    })
};

export const addPolygonZoneStart = () => ({
  type: actionTypes.ADD_POLYGON_ZONE_START
});

export const addPolygonZoneSuccess = (data) => ({
  type: actionTypes.ADD_POLYGON_ZONE_SUCCESS,
  data: data.deliveryArea
});

export const addPolygonZoneFail = (error) => ({
  type: actionTypes.ADD_POLYGON_ZONE_FAIL,
  error: error
});

export const addPolygonZone = (deliveryAreaId, name, polygon, price) => dispatch => {
  dispatch(addPolygonZoneStart());

  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/admin/addPolygonArea`, {
    deliveryAreaId,
    polygon,
    price,
    name
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(addPolygonZoneSuccess(res.data));
    })
    .catch(err => {
      dispatch(addPolygonZoneFail(err.response.data.message));
    })
};

export const updatePolygonZoneStart = () => ({
  type: actionTypes.UPDATE_POLYGON_ZONE_START
});

export const updatePolygonZoneSuccess = (data) => ({
  type: actionTypes.UPDATE_POLYGON_ZONE_SUCCESS,
  data: data.deliveryArea
});

export const updatePolygonZoneFail = (error) => ({
  type: actionTypes.UPDATE_POLYGON_ZONE_FAIL,
  error: error
});

export const updatePolygonZone = (deliveryAreaItemId, polygon) => dispatch => {
  dispatch(updatePolygonZoneStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/updatePolygonArea/${deliveryAreaItemId}`, {
    polygon
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(updatePolygonZoneSuccess(res.data));
    })
    .catch(err => {
      dispatch(updatePolygonZoneFail(err.response.data.message));
    })
};



export const checkDelivery = (placeId, restaurantId) => {

  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/admin/checkDelivery`, {
    placeId,
    restaurantId
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      console.log(res.data);
    })
    .catch(err => {
      console.log(err);
    })
};
