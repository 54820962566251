import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  deliveryArea: {},
  error: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DELIVERY_AREAS_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.GET_DELIVERY_AREAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deliveryArea: action.data
      };
    case actionTypes.GET_DELIVERY_AREAS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case actionTypes.ADD_DELIVERY_AREA_START:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.ADD_DELIVERY_AREA_SUCCESS:
      return {
        ...state,
        deliveryArea: action.data,
        isLoading: false
      }
    case actionTypes.ADD_DELIVERY_AREA_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.UPDATE_AREA_TYPE_START:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.UPDATE_AREA_TYPE_SUCCESS:
      return {
        ...state,
        deliveryArea: action.data,
        isLoading: false
      }
    case actionTypes.UPDATE_AREA_TYPE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.ADD_CIRCLE_ZONE_START:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.ADD_CIRCLE_ZONE_SUCCESS:
      return {
        ...state,
        deliveryArea: action.data,
        isLoading: false,
      }
    case actionTypes.ADD_CIRCLE_ZONE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.DELETE_DELIVERY_AREA_ITEM_START:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.DELETE_DELIVERY_AREA_ITEM_SUCCESS:
      return {
        ...state,
        deliveryArea: action.data,
        isLoading: false,
      }
    case actionTypes.DELETE_DELIVERY_AREA_ITEM_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.ADD_POLYGON_ZONE_START:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.ADD_POLYGON_ZONE_SUCCESS:
      return {
        ...state,
        deliveryArea: action.data,
        isLoading: false,
      }
    case actionTypes.ADD_POLYGON_ZONE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.UPDATE_POLYGON_ZONE_START:
      return {
        ...state,
      }
    case actionTypes.UPDATE_POLYGON_ZONE_SUCCESS:
      return {
        ...state,
        deliveryArea: action.data,
      }
    case actionTypes.UPDATE_POLYGON_ZONE_FAIL:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
};

export default reducer;
