import { React, useMemo } from "react";
import PropTypes from 'prop-types';

import { GoogleMap, MarkerF, useJsApiLoader, Circle } from "@react-google-maps/api";
import Loading from "../../../components/UI/Loading/Loading";

const Map = (props) => {
  const {
    centerPoint,
    deliveryArea
  } = props

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDA04ISkYwRpFCCdiOZWH8mAq72g7tW-qw',
  });

  const center = useMemo(() => ({ lat: centerPoint.lat, lng: centerPoint.lng }), [centerPoint]);

  const circleOneOptions = {
    fillColor: '#fde047',
    strokeColor: '#f59e0b',
    strokeOpacity: 0.9,
    strokeWeight: 0.5,
    fillOpacity: 0.15,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 4
  }

  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };

  if (!isLoaded || !centerPoint.lat) return <div><Loading /></div>;

  return (
    <GoogleMap
      zoom={11}
      options={options}
      center={center}
      mapContainerStyle={{ height: '450px', width: '100%' }}
    >
      <MarkerF position={center} />

      {
        deliveryArea && deliveryArea.deliveryAreaItems.length > 0
          ?
          deliveryArea.deliveryAreaItems.map((circle) => (
            <Circle key={circle._id} center={center} radius={circle.radius} options={circleOneOptions} />
          )
          )
          :
          null
      }
    </GoogleMap>
  );
};

Map.propTypes = {
  centerPoint: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  deliveryArea:
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      deliveryAreaItems: PropTypes.arrayOf(
        PropTypes.shape({
          radius: PropTypes.number
        })
      )
    }),
}

Map.defaultProps = {
  centerPoint: {},
  deliveryArea: {}
}

export default Map;
