import axios from 'axios';
import * as actionTypes from './actionTypes';

// add menu item
export const addMenuItemStart = () => ({
  type: actionTypes.ADD_MENU_ITEM_START
});

export const addMenuItemSuccess = (data) => ({
  type: actionTypes.ADD_MENU_ITEM_SUCCESS,
  data: data
});

export const addMenuItemFail = (error) => ({
  type: actionTypes.ADD_MENU_ITEM_FAIL,
  error: error
});

export const addMenuItem = (name, desc, image, price, active, wrapping, itemCategoryId, restaurantId) => dispatch => {
  dispatch(addMenuItemStart());
  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/admin/item`, {
    name,
    image,
    desc,
    price,
    itemCategoryId,
    active,
    wrapping,
    restaurantId
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(addMenuItemSuccess(res.data));
    })
    .catch(err => {
      dispatch(addMenuItemFail(err.response.data.message));
    })
}

// get menu items
export const getMenuItemsStart = () => ({
  type: actionTypes.GET_MENU_ITEMS_START
});

export const getMenuItemsSuccess = (data) => ({
  type: actionTypes.GET_MENU_ITEMS_SUCCESS,
  data: data
});

export const getMenuItemsFail = (error) => ({
  type: actionTypes.GET_MENU_ITEMS_FAIL,
  error: error
});

export const getMenuItems = (restaurantId) => dispatch => {
  dispatch(getMenuItemsStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/items/${restaurantId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(getMenuItemsSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getMenuItemsFail(err.response.data.message));
    })
};

// get single Menu Item
export const getMenuItemStart = () => ({
  type: actionTypes.GET_MENU_ITEM_START
});

export const getMenuItemSuccess = (data) => ({
  type: actionTypes.GET_MENU_ITEM_SUCCESS,
  data: data.data
});

export const getMenuItemFail = (error) => ({
  type: actionTypes.GET_MENU_ITEM_FAIL,
  error: error
});

export const getMenuItem = (menuItemId) => dispatch => {
  dispatch(getMenuItemStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/item/${menuItemId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getMenuItemSuccess(res.data));
    })
    .catch(err => {
      dispatch(getMenuItemFail(err.response.data.message));
    })
};

// delete menu item
export const deleteMenuItemStart = () => ({
  type: actionTypes.DELETE_MENU_ITEM_START
})

export const deleteMenuItemSuccess = (data) => ({
  type: actionTypes.DELETE_MENU_ITEM_SUCCESS,
  data: data
})

export const deleteMenuItemFail = (error) => ({
  type: actionTypes.DELETE_MENU_ITEM_FAIL,
  error: error
})

export const deleteMenuItem = (itemId) => dispatch => {
  dispatch(deleteMenuItemStart());

  const token = localStorage.getItem('token');
  axios.delete(`${process.env.REACT_APP_API}/admin/item/${itemId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(deleteMenuItemSuccess(res.data.itemId));
    })
    .catch(err => {
      dispatch(deleteMenuItemFail(err.response.data.message));
    })
}

// update menu item
export const updateMenuItemStart = () => ({
  type: actionTypes.UPDATE_MENU_ITEM_START
})

export const updateMenuItemSuccess = (data) => ({
  type: actionTypes.UPDATE_MENU_ITEM_SUCCESS,
  data: data
})

export const updateMenuItemFail = (error) => ({
  type: actionTypes.UPDATE_MENU_ITEM_FAIL,
  error: error
})

export const updateMenuItem = (id, name, desc, price, active, wrapping, image) => dispatch => {
  dispatch(updateMenuItemStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/item/${id}`, {
    name: name,
    desc: desc,
    price: price,
    active: active,
    wrapping: wrapping,
    image: image
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(updateMenuItemSuccess(res.data.item));
    })
    .catch(err => {
      dispatch(updateMenuItemFail(err.response.data.message));
    })
}

// update Menu Items Order
export const updateMenuItemsOrderStart = () => ({
  type: actionTypes.UPDATE_MENU_ITEMS_ORDER_START
});

export const updateMenuItemsOrderSuccess = (data) => ({
  type: actionTypes.UPDATE_MENU_ITEMS_ORDER_SUCCESS,
  data: data
});

export const updateMenuItemsOrderFail = (error) => ({
  type: actionTypes.UPDATE_MENU_ITEMS_ORDER_FAIL,
  error: error
});

export const updateMenuItemsOrder = (menuItemsInOrder, restaurantId) => dispatch => {
  dispatch(updateMenuItemsOrderStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/items`, {
    items: menuItemsInOrder,
    restaurantId
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(updateMenuItemsOrderSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(updateMenuItemsOrderFail(err.response.data.message));
    })
};
