import { React, useMemo } from "react";
import PropTypes from 'prop-types';

import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import Loading from "../../../components/UI/Loading/Loading";

import PolygonZone from "./PolygonZone";

const MapPolygon = (props) => {
  const {
    centerPoint,
    deliveryArea
  } = props

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDA04ISkYwRpFCCdiOZWH8mAq72g7tW-qw',
  });

  const center = useMemo(() => ({ lat: centerPoint.lat, lng: centerPoint.lng }), [centerPoint]);

  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: true,
  };

  // const handleClick = (event) => {
  //   const firstPointCopy = areaPoints[0];

  //   if (firstPointCopy && areaPoints.length === 2) {
  //     setAreaPoints([
  //       ...areaPoints,
  //       { lat: event.latLng.lat(), lng: event.latLng.lng() },
  //       firstPointCopy
  //     ])
  //   } else if (firstPointCopy && areaPoints.length > 2) {
  //     const areaPointsCopy = [...areaPoints].splice(0, [...areaPoints].length - 1);
  //     setAreaPoints([
  //       ...areaPointsCopy,
  //       { lat: event.latLng.lat(), lng: event.latLng.lng() },
  //       firstPointCopy
  //     ])
  //   } else {
  //     setAreaPoints([
  //       ...areaPoints,
  //       { lat: event.latLng.lat(), lng: event.latLng.lng() }
  //     ])
  //   }
  // };

  if (!isLoaded || !centerPoint.lat) return <div><Loading /></div>;


  return (
    <GoogleMap
      zoom={11}
      options={options}
      center={center}
      mapContainerStyle={{ height: '450px', width: '100%' }}
    >
      <MarkerF position={center} />

      {
        deliveryArea && deliveryArea.deliveryAreaItems.length > 0
          ?
          deliveryArea.deliveryAreaItems.map((polygon) => (
            <PolygonZone
              key={polygon._id}
              polygonCords={polygon.polygon}
              name={polygon.name}
              deliveryAreaItemId={polygon._id}
            />
          ))
          :
          null
      }
    </GoogleMap>
  );
};

MapPolygon.propTypes = {
  centerPoint: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  deliveryArea:
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      deliveryAreaItems: PropTypes.arrayOf(
        PropTypes.shape({
          radius: PropTypes.number
        })
      )
    }),
}

MapPolygon.defaultProps = {
  centerPoint: {},
  deliveryArea: {}
}

export default MapPolygon;
