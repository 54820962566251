import React from 'react';

const General = () => (
  <div>
    <h1>Ustawienia ogólne</h1>
    <div>
      <p>Lokal Dostępny</p>
      <p>Dostawy włączone</p>
      <p>Minimum Logistyczne</p>
      <p>Czas Realizacji</p>
    </div>
  </div>
);

export default General;
