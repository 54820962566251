import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import ItemOptionsGroupList from './ItemOptionsGroupList';
import Modal from '../../../../components/UI/Modal/Modal';
import useModal from '../../../../hooks/useModal/useModal';
import useConfirmationModal from '../../../../hooks/UseConfirmationModal/useConfirmationModal';
import ConfirmationModal from '../../../../components/UI/ConfirmationModal/ConfirmationModal';
import MenuItem from './MenuItem';
import * as actions from '../../../../state/actions/index';
import ItemOverlay from './ItemOverlay';
import EditMenuItemForm from '../../../../components/Forms/EditMenuItemForm/EditMenuItemForm';

const MenuItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
`;

const MenuItemList = (props) => {
  const {
    menuItems,
    itemCategoryId,
    updateMenuItemsOrder,
    deleteMenuItem
  } = props;

  const [isShowingConfirmationModal, toggleConfirmationModal] = useConfirmationModal();
  const [itemId, setItemId] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [isShowingModal, toggleModal] = useModal();
  const [isShowingOptionsModal, toggleOptionsModal] = useModal();
  const [filteredMenuItems, setFilteredMenuItems] = useState(() => menuItems.filter(item => itemCategoryId === item.itemCategory ? item : null).sort((a, b) => a.orderKey - b.orderKey));

  useEffect(() => {
    setFilteredMenuItems(() => menuItems.filter(item => itemCategoryId === item.itemCategory ? item : null).sort((a, b) => a.orderKey - b.orderKey))
  }, [menuItems])

  const itemIds = useMemo(() => filteredMenuItems.map((item) => item._id), [filteredMenuItems]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleItemDelete = (id) => {
    toggleConfirmationModal();
    setItemId(id);
  }

  const handleConfirmDelete = (id) => {
    deleteMenuItem(id);
    toggleConfirmationModal();
  }

  const handleItemEdit = (id) => {
    toggleModal();
    setItemId(id);
  }

  const handleItemOptions = (id) => {
    toggleOptionsModal();
    setItemId(id);
  }

  const handleDragStart = (event) => {
    const { active } = event;

    setActiveId(active.id);
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFilteredMenuItems((items) => {
        const oldIndex = items.map(e => e._id).indexOf(active.id);
        const newIndex = items.map(e => e._id).indexOf(over.id);

        const updatedOrder = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
          ...item,
          orderKey: index + 1
        }));
        updateMenuItemsOrder(updatedOrder, window.localStorage.getItem('activeRestaurant'));
        return updatedOrder;
      })
    }
  }

  return (
    <MenuItemContainer>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={itemIds}
          strategy={verticalListSortingStrategy}
        >
          {
            filteredMenuItems.map(item => (
              <MenuItem
                key={item._id}
                id={item._id}
                name={item.name}
                desc={item.desc}
                image={item.image}
                active={item.active}
                price={item.price}
                wrapping={item.wrapping}
                handleItemDelete={() => handleItemDelete(item._id)}
                handleItemEdit={() => handleItemEdit(item._id)}
                handleItemOptions={() => handleItemOptions(item._id)}
              />
            ))
          }
        </SortableContext>
        <DragOverlay>
          {
            activeId
              ?
              (
                filteredMenuItems.filter((item) => item._id === activeId ? item : null)
                  .map((overlayItem) => (
                    <ItemOverlay
                      key={activeId}
                      id={activeId}
                      name={overlayItem.name}
                      desc={overlayItem.desc}
                      image={overlayItem.image}
                      active={overlayItem.active}
                      price={overlayItem.price}
                      wrapping={overlayItem.wrapping}
                    />
                  ))
              )
              :
              null
          }
        </DragOverlay>
      </DndContext>
      <ConfirmationModal
        show={isShowingConfirmationModal}
        clicked={toggleConfirmationModal}
        title="Potwierdź operację"
        text="Czy chcesz usunąć ten produkt?"
        onConfirm={() => handleConfirmDelete(itemId)}
      />
      <Modal
        show={isShowingModal}
        clicked={toggleModal}
      >
        <EditMenuItemForm menuItemId={itemId} />
      </Modal>
      <Modal
        show={isShowingOptionsModal}
        clicked={toggleOptionsModal}
      >
        <ItemOptionsGroupList itemId={itemId} />
      </Modal>
    </MenuItemContainer>
  );
};



const mapStateToProps = state => ({
  menuItems: state.items.items
});

const mapDispatchToProps = dispatch => ({
  updateMenuItemsOrder: (menuItemsInOrder, restaurantId) => dispatch(actions.updateMenuItemsOrder(menuItemsInOrder, restaurantId)),
  deleteMenuItem: (itemId) => dispatch(actions.deleteMenuItem(itemId))
});

MenuItemList.propTypes = {
  deleteMenuItem: PropTypes.func.isRequired,
  updateMenuItemsOrder: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    active: PropTypes.bool,
    wrapping: PropTypes.bool
  })),
  itemCategoryId: PropTypes.string.isRequired
}

MenuItemList.defaultProps = {
  menuItems: []
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemList);
