import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  hours: [],
  isLoadingHours: false,
  hoursError: null
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HOURS_START:
      return {
        ...state,
        isLoadingHours: true
      }
    case actionTypes.GET_HOURS_SUCCESS:
      return {
        ...state,
        isLoadingHours: false,
        hours: action.data.data,
        hoursError: null
      }
    case actionTypes.GET_HOURS_FAIL:
      return {
        ...state,
        isLoadingHours: false,
        hoursError: action.error
      }
    case actionTypes.UPDATE_HOURS_START:
      return {
        ...state,
        isLoadingHours: true
      }
    case actionTypes.UPDATE_HOURS_SUCCESS:
      return {
        ...state,
        isLoadingHours: false,
        hours: action.data,
        hoursError: null
      }
    case actionTypes.UPDATE_HOURS_FAIL:
      return {
        ...state,
        isLoadingHours: false,
        hoursError: action.error
      }
    default:
      return state;
  }
}

export default reducer;