import React, { useState } from 'react';
import { ChevronDown, Target, X, Loader } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as actions from '../../../state/actions/index';

const StyledDropdown = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 280px;
`;

const DropdownButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 16px;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  color: #f9f9f9;
  background: ${({ theme }) => theme.accentColor.primary};
  border: 0;
  cursor: pointer;
  z-index: 2;
  transition: all .2s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.accentColor.primaryHover};
  }

  & p{
    font-size: 14px;
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    font-weight: 600;
  }
`;

const DropdownIcon = styled.span`
  margin-top: -1px;
`;

const DropdownToggleIcon = styled.span`
  margin-left: auto;
  margin-top: 2px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 42px;
  left: 0px;
  width: 100%;
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 0;
  background:${({ theme }) => theme.accentColor.secondary};
  translate: ${props => props.isOpen ? '0' : '0 -20px'};
  transition: 0.4s;
  z-index: 1;
`;

const DropdownMenuButton = styled.button`
  border: 0;
  width: 100%;
  height: 56px;
  border-radius: 0;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover{
    background-color: #1c354b;
  }

  & p{
    font-size: 12px;
    color: ${({ theme }) => theme.neutralColor.white};
    font-weight: 600;
  }
`;


const Dropdown = (props) => {
  const {
    updateAreaType,
    deliveryArea,
    isLoadingDeliveryData
  } = props;

  const [dropdownToggled, setDropdowToggled] = useState(false);

  const handleDropdownToggle = () => {
    setDropdowToggled((prev) => !prev);
  }

  const handleDeliveryAreaTypeChange = (areaType) => {
    updateAreaType(areaType, deliveryArea._id);
    setDropdowToggled((prev) => !prev);
  }

  const toggleIcon = (toggled) => toggled === false ? <ChevronDown /> : <X />;

  return (
    <>
      {
        !isLoadingDeliveryData
          ?
          (<StyledDropdown>
            <DropdownButton onClick={() => handleDropdownToggle()}>
              <DropdownIcon>
                <Target />
              </DropdownIcon>
              {deliveryArea.type === 'circle' ? 'Strefy regularne' : 'Nieregularna Strefa'}
              <DropdownToggleIcon>
                {toggleIcon(dropdownToggled)}
              </DropdownToggleIcon>
            </DropdownButton>
            <DropdownMenu isOpen={dropdownToggled}>
              <DropdownMenuButton onClick={() => handleDeliveryAreaTypeChange("circle")}>
                <p>Strefy regularne</p>
              </DropdownMenuButton>
              <DropdownMenuButton onClick={() => handleDeliveryAreaTypeChange("polygon")}>
                <p>Nieregularna Strefa</p>
              </DropdownMenuButton>
            </DropdownMenu>
          </StyledDropdown>)
          :
          <StyledDropdown>
            <DropdownButton>
              <DropdownIcon>
                <Target />
              </DropdownIcon>
              ...
              <DropdownToggleIcon>
                <Loader />
              </DropdownToggleIcon>
            </DropdownButton>
          </StyledDropdown>
      }
    </>
  );
};

const mapStateToProps = state => ({
  deliveryArea: state.deliveryAreas.deliveryArea,
  isLoadingRestaurants: state.restaurants.isLoading,
  isLoadingDeliveryData: state.deliveryAreas.isLoading
});

const mapDispatchToProps = dispatch => ({
  updateAreaType: (areaType, DeliveryAreaId) => dispatch(actions.updateAreaType(areaType, DeliveryAreaId))
});

Dropdown.propTypes = {
  activeRestaurant: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  }),
  deliveryArea: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string
  }),
  updateAreaType: PropTypes.func.isRequired,
  isLoadingDeliveryData: PropTypes.bool.isRequired
}

Dropdown.defaultProps = {
  activeRestaurant: {},
  deliveryArea: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
