export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_LOGIN_CHECK = 'AUTH_LOGIN_CHECK';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const GET_RESTAURANTS_START = 'GET_RESTAURANTS_START';
export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_FAIL = 'GET_RESTAURANTS_FAIL';

export const ARCHIVE_RESTAURANT_START = 'ARCHIVE_RESTAURANT_START';
export const ARCHIVE_RESTAURANT_SUCCESS = 'ARCHIVE_RESTAURANT_SUCCESS';
export const ARCHIVE_RESTAURANT_FAIL = 'ARCHIVE_RESTAURANT_FAIL';

export const ADD_RESTAURANT_START = 'ADD_RESTAURANT_START';
export const ADD_RESTAURANT_SUCCESS = 'ADD_RESTAURANT_SUCCESS';
export const ADD_RESTAURANT_FAIL = 'ADD_RESTAURANT_FAIL';

export const CHANGE_ACTIVE_RESTAURANT_START = 'CHANGE_ACTIVE_RESTAURANT_START';
export const CHANGE_ACTIVE_RESTAURANT_SUCCESS = 'CHANGE_ACTIVE_RESTAURANT_SUCCESS';
export const CHANGE_ACTIVE_RESTAURANT_FAIL = 'CHANGE_ACTIVE_RESTAURANT_FAIL';

export const GET_ACTIVE_RESTAURANT_CORDS_START = 'GET_ACTIVE_RESTAURANT_CORDS_START';
export const GET_ACTIVE_RESTAURANT_CORDS_SUCCESS = 'GET_ACTIVE_RESTAURANT_CORDS_SUCCESS';
export const GET_ACTIVE_RESTAURANT_CORDS_FAIL = 'GET_ACTIVE_RESTAURANT_CORDS_FAIL';

export const GET_ACTIVE_RESTAURANT_START = 'GET_ACTIVE_RESTAURANT_START';
export const GET_ACTIVE_RESTAURANT_SUCCESS = 'GET_ACTIVE_RESTAURANT_SUCCESS';
export const GET_ACTIVE_RESTAURANT_FAIL = 'GET_ACTIVE_RESTAURANT_FAIL';

export const GET_USER_DATA_START = 'GET_USER_DATA_START';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const ADD_IMAGE_START = 'ADD_IMAGE_START';
export const ADD_IMAGE_SUCCESS = 'ADD_IMAGE_SUCCESS';
export const ADD_IMAGE_FAIL = 'ADD_IMAGE_FAIL';

export const GET_IMAGES_START = 'GET_IMAGES_START';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_FAIL = 'GET_IMAGES_FAIL';

export const DELETE_IMAGE_START = 'DELETE_IMAGE_START';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL';

export const ADD_ITEM_CATEGORY_START = 'ADD_ITEM_CATEGORY_START';
export const ADD_ITEM_CATEGORY_SUCCESS = 'ADD_ITEM_CATEGORY_SUCCESS';
export const ADD_ITEM_CATEGORY_FAIL = 'ADD_ITEM_CATEGORY_FAIL';

export const GET_ITEM_CATEGORIES_START = 'GET_ITEM_CATEGORIES_START';
export const GET_ITEM_CATEGORIES_SUCCESS = 'GET_ITEM_CATEGORIES_SUCCESS';
export const GET_ITEM_CATEGORIES_FAIL = 'GET_ITEM_CATEGORIES_FAIL';

export const GET_ITEM_CATEGORY_START = 'GET_ITEM_CATEGORY_START';
export const GET_ITEM_CATEGORY_SUCCESS = 'GET_ITEM_CATEGORY_SUCCESS';
export const GET_ITEM_CATEGORY_FAIL = 'GET_ITEM_CATEGORY_FAIL';

export const UPDATE_ITEM_CATEGORY_START = 'UPDATE_ITEM_CATEGORY_START';
export const UPDATE_ITEM_CATEGORY_SUCCESS = 'UPDATE_ITEM_CATEGORY_SUCCESS';
export const UPDATE_ITEM_CATEGORY_FAIL = 'UPDATE_ITEM_CATEGORY_FAIL';

export const DELETE_ITEM_CATEGORY_START = 'DELETE_ITEM_CATEGORY_START';
export const DELETE_ITEM_CATEGORY_SUCCESS = 'DELETE_ITEM_CATEGORY_SUCCESS';
export const DELETE_ITEM_CATEGORY_FAIL = 'DELETE_ITEM_CATEGORY_FAIL';

export const UPDATE_ITEM_CATEGORY_ORDER_START = 'UPDATE_ITEM_CATEGORY_ORDER_START';
export const UPDATE_ITEM_CATEGORY_ORDER_SUCCESS = 'UPDATE_ITEM_CATEGORY_ORDER_SUCCESS';
export const UPDATE_ITEM_CATEGORY_ORDER_FAIL = 'UPDATE_ITEM_CATEGORY_ORDER_FAIL';

export const ADD_MENU_ITEM_START = 'ADD_MENU_ITEM_START';
export const ADD_MENU_ITEM_SUCCESS = 'ADD_MENU_ITEM_SUCCESS';
export const ADD_MENU_ITEM_FAIL = 'ADD_MENU_ITEM_FAIL';

export const GET_MENU_ITEMS_START = 'GET_MENU_ITEMS_START';
export const GET_MENU_ITEMS_SUCCESS = 'GET_MENU_ITEMS_SUCCESS';
export const GET_MENU_ITEMS_FAIL = 'GET_MENU_ITEMS_FAIL';

export const GET_MENU_ITEM_START = 'GET_MENU_ITEM_START';
export const GET_MENU_ITEM_SUCCESS = 'GET_MENU_ITEM_SUCCESS';
export const GET_MENU_ITEM_FAIL = 'GET_MENU_ITEM_FAIL';

export const UPDATE_MENU_ITEM_START = 'UPDATE_MENU_ITEM_START';
export const UPDATE_MENU_ITEM_SUCCESS = 'UPDATE_MENU_ITEM_SUCCESS';
export const UPDATE_MENU_ITEM_FAIL = 'UPDATE_MENU_ITEM_FAIL';

export const DELETE_MENU_ITEM_START = 'DELETE_MENU_ITEM_START';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_FAIL = 'DELETE_MENU_ITEM_FAIL';

export const UPDATE_MENU_ITEMS_ORDER_START = 'UPDATE_MENU_ITEMS_ORDER_START';
export const UPDATE_MENU_ITEMS_ORDER_SUCCESS = 'UPDATE_MENU_ITEMS_ORDER_SUCCESS';
export const UPDATE_MENU_ITEMS_ORDER_FAIL = 'UPDATE_MENU_ITEMS_ORDER_FAIL';

export const GET_ITEMS_OPTION_GROUP_START = 'GET_ITEMS_OPTION_GROUP_START';
export const GET_ITEMS_OPTION_GROUP_SUCCESS = 'GET_ITEMS_OPTION_GROUP_SUCCESS';
export const GET_ITEMS_OPTION_GROUP_FAIL = 'GET_ITEMS_OPTION_GROUP_FAIL';

export const ADD_ITEMS_OPTION_GROUP_START = 'ADD_ITEMS_OPTION_GROUP_START';
export const ADD_ITEMS_OPTION_GROUP_SUCCESS = 'ADD_ITEMS_OPTION_GROUP_SUCCESS';
export const ADD_ITEMS_OPTION_GROUP_FAIL = 'ADD_ITEMS_OPTION_GROUP_FAIL';

export const DELETE_ITEMS_OPTION_GROUP_START = 'DELETE_ITEMS_OPTION_GROUP_START';
export const DELETE_ITEMS_OPTION_GROUP_SUCCESS = 'DELETE_ITEMS_OPTION_GROUP_SUCCESS';
export const DELETE_ITEMS_OPTION_GROUP_FAIL = 'DELETE_ITEMS_OPTION_GROUP_FAIL';

export const UPDATE_ITEMS_OPTION_GROUP_START = 'UPDATE_ITEMS_OPTION_GROUP_START';
export const UPDATE_ITEMS_OPTION_GROUP_SUCCESS = 'UPDATE_ITEMS_OPTION_GROUP_SUCCESS';
export const UPDATE_ITEMS_OPTION_GROUP_FAIL = 'UPDATE_ITEMS_OPTION_GROUP_FAIL';

export const GET_ITEM_OPTIONS_START = 'GET_ITEM_OPTIONS_START';
export const GET_ITEM_OPTIONS_SUCCESS = 'GET_ITEM_OPTIONS_SUCCESS';
export const GET_ITEM_OPTIONS_FAIL = 'GET_ITEM_OPTIONS_FAIL';

export const ADD_ITEM_OPTION_START = 'ADD_ITEM_OPTION_START';
export const ADD_ITEM_OPTION_SUCCESS = 'ADD_ITEM_OPTION_SUCCESS';
export const ADD_ITEM_OPTION_FAIL = 'ADD_ITEM_OPTION_FAIL';

export const DELETE_ITEM_OPTION_START = 'DELETE_ITEM_OPTION_START';
export const DELETE_ITEM_OPTION_SUCCESS = 'DELETE_ITEM_OPTION_SUCCESS';
export const DELETE_ITEM_OPTION_FAIL = 'DELETE_ITEM_OPTION_FAIL';

export const UPDATE_ITEM_OPTION_START = 'UPDATE_ITEM_OPTION_START';
export const UPDATE_ITEM_OPTION_SUCCESS = 'UPDATE_ITEM_OPTION_SUCCESS';
export const UPDATE_ITEM_OPTION_FAIL = 'UPDATE_ITEM_OPTION_GROUP_FAIL';

export const GET_DELIVERY_AREAS_START = 'GET_DELIVERY_AREAS_START';
export const GET_DELIVERY_AREAS_SUCCESS = 'GET_DELIVERY_AREAS_SUCCESS';
export const GET_DELIVERY_AREAS_FAIL = 'GET_DELIVERY_AREAS_FAIL';

export const ADD_DELIVERY_AREA_START = 'ADD_DELIVERY_AREA_START';
export const ADD_DELIVERY_AREA_SUCCESS = 'ADD_DELIVERY_AREA_SUCCESS';
export const ADD_DELIVERY_AREA_FAIL = 'ADD_DELIVERY_AREA_FAIL';

export const UPDATE_AREA_TYPE_START = 'UPDATE_AREA_TYPE_START';
export const UPDATE_AREA_TYPE_SUCCESS = 'UPDATE_AREA_TYPE_SUCCESS';
export const UPDATE_AREA_TYPE_FAIL = 'UPDATE_AREA_TYPE_FAIL';

export const ADD_CIRCLE_ZONE_START = 'ADD_CIRCLE_ZONE_START';
export const ADD_CIRCLE_ZONE_SUCCESS = 'ADD_CIRCLE_ZONE_SUCCESS';
export const ADD_CIRCLE_ZONE_FAIL = 'ADD_CIRCLE_ZONE_FAIL';

export const DELETE_DELIVERY_AREA_ITEM_START = 'DELETE_DELIVERY_AREA_ITEM_START';
export const DELETE_DELIVERY_AREA_ITEM_SUCCESS = 'DELETE_DELIVERY_AREA_ITEM_SUCCESS';
export const DELETE_DELIVERY_AREA_ITEM_FAIL = 'DELETE_DELIVERY_AREA_ITEM_FAIL';

export const ADD_POLYGON_ZONE_START = 'ADD_POLYGON_ZONE_START';
export const ADD_POLYGON_ZONE_SUCCESS = 'ADD_POLYGON_ZONE_SUCCESS';
export const ADD_POLYGON_ZONE_FAIL = 'ADD_POLYGON_ZONE_FAIL';

export const UPDATE_POLYGON_ZONE_START = 'UPDATE_POLYGON_ZONE_START';
export const UPDATE_POLYGON_ZONE_SUCCESS = 'UPDATE_POLYGON_ZONE_SUCCESS';
export const UPDATE_POLYGON_ZONE_FAIL = 'UPDATE_POLYGON_ZONE_FAIL';

export const GET_CART_START = 'GET_CART_START';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAIL = 'GET_CART_FAIL';

export const GET_ORDER_START = 'GET_ORDER_START';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const GET_ORDERS_START = 'GET_ORDERS_START';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';

export const UPDATE_ORDERS = 'UPDATE_ORDERS';

export const UPDATE_ORDER_STATUS_START = 'UPDATE_ORDER_STATUS_START';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAIL = 'UPDATE_ORDER_STATUS_FAIL';

export const GET_HOURS_START = 'GET_HOURS_START';
export const GET_HOURS_SUCCESS = 'GET_HOURS_SUCCESS';
export const GET_HOURS_FAIL = 'GET_HOURS_FAIL';

export const UPDATE_HOURS_START = 'UPDATE_HOURS_START';
export const UPDATE_HOURS_SUCCESS = 'UPDATE_HOURS_SUCCESS';
export const UPDATE_HOURS_FAIL = 'UPDATE_HOURS_FAIL';

export const GET_RESTAURANT_INFO_START = 'GET_RESTAURANT_INFO_START';
export const GET_RESTAURANT_INFO_SUCCESS = 'GET_RESTAURANT_INFO_SUCCESS';
export const GET_RESTAURANT_INFO_FAIL = 'GET_RESTAURANT_INFO_FAIL';

export const UPDATE_RESTAURANT_INFO_START = 'UPDATE_RESTAURANT_INFO_START';
export const UPDATE_RESTAURANT_INFO_SUCCESS = 'UPDATE_RESTAURANT_INFO_SUCCESS';
export const UPDATE_RESTAURANT_INFO_FAIL = 'UPDATE_RESTAURANT_INFO_FAIL';