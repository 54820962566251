import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Map from './Map';
import ManageDeliveryAreaCircle from './ManageDeliveryAreaCircle';
import Loading from '../../../components/UI/Loading/Loading';

const DeliveryContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;

const DeliveryMap = styled.div`
  display: flex;
  flex-basis: 50%;
`;

const DeliveryManage = styled.div`
  display: flex;
  flex-basis: 50%;
`;

const DeliveryAreaCircle = (props) => {
  const {
    deliveryArea,
    placeData,
    isLoadingCords
  } = props;

  return (
    <DeliveryContainer>
      <DeliveryMap>
        {
          !isLoadingCords
            ?
            <Map centerPoint={placeData} deliveryArea={deliveryArea} />
            :
            <Loading />
        }
      </DeliveryMap>
      <DeliveryManage>
        <ManageDeliveryAreaCircle deliveryArea={deliveryArea} />
      </DeliveryManage>
    </DeliveryContainer>
  );
};

const mapStateToProps = state => ({
  placeData: state.restaurants.cords,
  isLoadingCords: state.restaurants.isLoadingCords
});

DeliveryAreaCircle.propTypes = {
  placeData: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }).isRequired,
  deliveryArea: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string
  }),
  isLoadingCords: PropTypes.bool.isRequired
};

DeliveryAreaCircle.defaultProps = {
  deliveryArea: {}
};


export default connect(mapStateToProps, null)(DeliveryAreaCircle);
