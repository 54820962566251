import axios from 'axios';
import * as actionTypes from './actionTypes';

// get one order
export const getOrderStart = () => ({
  type: actionTypes.GET_ORDER_START
});

export const getOrderSuccess = (data) => ({
  type: actionTypes.GET_ORDER_SUCCESS,
  data: data
});

export const getOrderFail = (error) => ({
  type: actionTypes.GET_ORDER_FAIL,
  error: error
});

export const getOrder = (orderId) => dispatch => {
  dispatch(getOrderStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/order/${orderId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(getOrderSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getOrderFail(err.response.data.message));
    })
};

// add orders by date
export const getOrdersStart = () => ({
  type: actionTypes.GET_ORDERS_START
});

export const getOrdersSuccess = (data) => ({
  type: actionTypes.GET_ORDERS_SUCCESS,
  data: data
});

export const getOrdersFail = (error) => ({
  type: actionTypes.GET_ORDERS_FAIL,
  error: error
});

export const getOrders = (date) => dispatch => {
  dispatch(getOrdersStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/orders/${date}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      console.log(res.data.data);
      dispatch(getOrdersSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getOrdersFail(err.response.data.message));
    })
};

export const updateOrders = (order) => ({
  type: actionTypes.UPDATE_ORDERS,
  data: order
});

export const updateOrderStatusStart = () => ({
  type: actionTypes.UPDATE_ORDER_STATUS_START
});

export const updateOrderStatusSuccess = (data) => ({
  type: actionTypes.UPDATE_ORDER_STATUS_SUCCESS,
  data: data
});

export const updateOrderStatusFail = (error) => ({
  type: actionTypes.UPDATE_ORDER_STATUS_FAIL,
  error: error
});

export const updateOrderStatus = (orderId, status) => dispatch => {
  dispatch(updateOrderStatusStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/order`, {
    orderId,
    status
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      console.log(res.data.data);
      dispatch(updateOrderStatusSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(updateOrderStatusFail(err.response.data.message));
    })
};


// get cart
export const getCartStart = () => ({
  type: actionTypes.GET_CART_START
});

export const getCartSuccess = (data) => ({
  type: actionTypes.GET_CART_SUCCESS,
  data: data
});

export const getCartFail = (error) => ({
  type: actionTypes.GET_CART_FAIL,
  error: error
});

export const getCart = (cartId, restaurantId) => dispatch => {
  dispatch(getCartStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/cart/${cartId}/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      if (res.data.rightRestaurant === true) {
        dispatch(getCartSuccess(res.data));
      } else {
        localStorage.removeItem('cart');
        dispatch(getCartFail(res.data.message))
      }
    })
    .catch(err => {
      localStorage.removeItem('cart');
      dispatch(getCartFail(err.response.data.message));
    })
}