import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  optionsGroups: [],
  isLoading: false,
  loadingError: null,

  isAdding: false,
  addingError: null,

  isDeleting: false,
  updatingError: null
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ITEMS_OPTION_GROUP_START:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GET_ITEMS_OPTION_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        optionsGroups: action.data,
        loadingError: null
      }
    case actionTypes.GET_ITEMS_OPTION_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        loadingError: action.error
      }
    case actionTypes.ADD_ITEMS_OPTION_GROUP_START:
      return {
        ...state,
        isAdding: true
      }
    case actionTypes.ADD_ITEMS_OPTION_GROUP_SUCCESS:
      return {
        ...state,
        isAdding: false,
        optionsGroups: [...state.optionsGroups, action.data.itemOptionsGroup],
        addingError: null
      }
    case actionTypes.ADD_ITEMS_OPTION_GROUP_FAIL:
      return {
        ...state,
        isAdding: false,
        addingError: action.error
      }
    case actionTypes.DELETE_ITEMS_OPTION_GROUP_START:
      return {
        ...state,
        isDeleting: true
      }
    case actionTypes.DELETE_ITEMS_OPTION_GROUP_SUCCESS:
      return {
        ...state,
        optionsGroups: state.optionsGroups.filter(optionsGroup =>
          optionsGroup._id !== action.data
        ),
        isDeleting: false
      }
    case actionTypes.DELETE_ITEMS_OPTION_GROUP_FAIL:
      return {
        ...state,
        isDeleting: false,
        deletingError: action.error
      }
    case actionTypes.UPDATE_ITEMS_OPTION_GROUP_START:
      return {
        ...state,
      }
    case actionTypes.UPDATE_ITEMS_OPTION_GROUP_SUCCESS:
      return {
        ...state,
        optionsGroups: state.optionsGroups.map(optionsGroup =>
          optionsGroup._id === action.data._id ? action.data : optionsGroup
        )
      }
    case actionTypes.UPDATE_ITEMS_OPTION_GROUP_FAIL:
      return {
        ...state,
        updatingError: action.error
      }
    default:
      return state;
  }
}

export default reducer;
