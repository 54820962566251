import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  items: [],
  isAdding: false,
  isLoading: false,
  getError: null,
  addingError: null,

  menuItem: {},
  getMenuItemIsLoading: false,
  getMenuItemError: null
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MENU_ITEM_START:
      return {
        ...state,
        isAdding: true
      }
    case actionTypes.ADD_MENU_ITEM_SUCCESS:
      return {
        ...state,
        isAdding: false,
        items: [...state.items, action.data.item],
        addingError: null
      }
    case actionTypes.ADD_MENU_ITEM_FAIL:
      return {
        ...state,
        isAdding: false,
        addingError: action.error
      }
    case actionTypes.GET_MENU_ITEMS_START:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GET_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.data,
        getError: null
      }
    case actionTypes.GET_MENU_ITEMS_FAIL:
      return {
        ...state,
        isLoading: false,
        getError: action.error
      }
    case actionTypes.GET_MENU_ITEM_START:
      return {
        ...state,
        getMenuItemIsLoading: true
      };
    case actionTypes.GET_MENU_ITEM_SUCCESS:
      return {
        ...state,
        getMenuItemIsLoading: false,
        menuItem: action.data,
        getMenuItemError: null
      }
    case actionTypes.GET_MENU_ITEM_FAIL:
      return {
        ...state,
        getMenuItemIsLoading: false,
        getMenuItemError: action.error
      }
    case actionTypes.UPDATE_MENU_ITEMS_ORDER_START:
      return {
        ...state,
      }
    case actionTypes.UPDATE_MENU_ITEMS_ORDER_SUCCESS:
      return {
        ...state,
        items: action.data,
        getError: null
      }
    case actionTypes.UPDATE_MENU_ITEMS_ORDER_FAIL:
      return {
        ...state,
        getError: action.error
      }
    case actionTypes.DELETE_MENU_ITEM_START:
      return {
        ...state,
        isDeleting: true
      }
    case actionTypes.DELETE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item =>
          item._id !== action.data
        ),
        isDeleting: false
      }
    case actionTypes.DELETE_MENU_ITEM_FAIL:
      return {
        ...state,
        isDeleting: false,
        deletingError: action.error
      }
    case actionTypes.UPDATE_MENU_ITEM_START:
      return {
        ...state,
        isUpdating: true
      }
    case actionTypes.UPDATE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        items: state.items.map(item =>
          item._id === action.data._id ? action.data : item
        ),
        isUpdating: false
      }
    case actionTypes.UPDATE_MENU_ITEM_FAIL:
      return {
        ...state,
        isUpdating: false,
        error: action.error
      }
    default:
      return state;
  }
}

export default reducer;
