/* eslint-disable react/destructuring-assignment */
import React from 'react';
import '../../../assets/PTSans-Regular-normal';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import Button from '../../UI/Button/Button';

const PrintButton = styled(Button)`
  && {
    background-color: green;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`;

const OrderPrint = (order) => {
  console.log(order);
  const handlePrint = (orderToPrint, cartToPrint) => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'mm', [80, 140]);
    pdf.setFont('PTSans-Regular');
    pdf.setFontSize('10');
    pdf.text(
      [
        `#${orderToPrint.orderNumber}`,
        `${`${new Date(orderToPrint.orderDate).getDate()}/${new Date(orderToPrint.orderDate).getMonth()+1}/${new Date(orderToPrint.orderDate).getFullYear()}`}`
      ], 40, 10, { align: 'center' });

    pdf.setDrawColor(255, 255, 255);

    // eslint-disable-next-line react/destructuring-assignment
    cartToPrint.forEach(item => {
      pdf.cell(0, 20, 10, 8, `${item.quantity}x`, `${item._id}`);
      pdf.cell(0, 20, 50, 8, `${item.product.name}`, `${item._id}`);
      // pdf.cell(0, 20, 50, 8, `${item.order} ${!item.option ? null : item.option.join(", ")}`, `${item._id}`);
      pdf.cell(0, 20, 20, 8, `${(item.product.price * item.quantity).toFixed(2)}`, `${item._id}`);
    })

    // przerwa
    pdf.cell(0, 20, 60, 6, " ", "pause");


    // Podsumowanie
    if (orderToPrint.realizationType === 'delivery') {
      pdf.cell(0, 20, 60, 8, "Dostawa", "1");
      pdf.cell(0, 20, 20, 8, `${orderToPrint.price.deliveryPrice.toFixed(2)}`, "1", "right");
    }
    pdf.cell(0, 20, 60, 8, "Opakowanie", "2");
    pdf.cell(0, 20, 20, 8, `${orderToPrint.price.wrappingPrice.toFixed(2)}`, "2", "right");

    pdf.cell(0, 20, 60, 8, "Cena Zamówienia", "3");
    pdf.cell(0, 20, 20, 8, `${orderToPrint.price.cartPrice.toFixed(2)}`, "3", "right");

    pdf.cell(0, 20, 60, 8, "Cena Ogólna", "4");
    pdf.cell(0, 20, 20, 8, `${orderToPrint.price.finalPrice.toFixed(2)}`, "4", "right");

    // adres
    if (orderToPrint.realizationType === 'delivery') {
      pdf.cell(0, 20, 50, 16, `${orderToPrint.deliveryData.locality}, ${orderToPrint.deliveryData.street} ${orderToPrint.deliveryData.localNumber}, ${orderToPrint.deliveryData.localNumber ? 'mieszkanie' : ''} ${orderToPrint.deliveryData.localNumber ? orderToPrint.deliveryData.localNumber : ''}`, "5");
    }
    // Imię i nazwisko
    pdf.cell(0, 20, 80, 8, `${orderToPrint.userData.name} ${orderToPrint.userData.surname}`, "6");
    // telefon
    pdf.cell(0, 20, 80, 8, `${orderToPrint.userData.phone}`, "7");
    // additional info
    if (orderToPrint.other.additionalInfo) {
      pdf.cell(0, 20, 80, 8, `${orderToPrint.other.additionalInfo}`, "8");
    }
    // sposób płatności
    pdf.cell(0, 20, 40, 8, `${orderToPrint.payment.paymentTypeName}`, "9");
    pdf.cell(0, 20, 40, 8, `Opłacone: ${orderToPrint.payment.status ? 'Tak' : 'Nie'}`, "9", "right");

    pdf.autoPrint();
    window.open(pdf.output('bloburl'), '_blank', "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=150");
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <div style={{ position: 'absolute', top: '0px', left: '3000px', width: '80mm' }}>
          <div id="divToPrint" style={{ fontFamily: 'PTSans-Regular', fontSize: '10pt', display: 'none' }}>
            <div style={{ fontSize: '12px', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
              <div>#{order.orderNumber}</div>
              <div>{`${new Date(order.orderDate).getDate()}/${new Date(order.orderDate).getMonth()+1}/${new Date(order.orderDate).getFullYear()}`}</div>
              
              <div style={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '20px' }}>
              {
                order.realizationType === 'delivery'
                ?
                <div className="d-flex justify-content-between"><div>Cena dostawy:</div><div>  {order.order.price.deliveryPrice} ZŁ</div></div>
                :
                null
              }
                <div className="d-flex justify-content-between"><div>Cena opakowania:</div><div>  {order.order.price.wrappingPrice} ZŁ</div></div>
                <div className="d-flex justify-content-between"><div>Cena zamówienia:</div><div>  {order.order.price.cartPrice} ZŁ</div></div>
                <div className="d-flex justify-content-between"><div>Cena ogólna:</div><div> {order.order.price.finalPrice} ZŁ</div></div>
              </div>
              <div style={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '20px' }}>
                <div>{order.order.userData.name} {order.order.userData.surname}</div>
                <div>{order.order.userData.phone}</div>
                <div>{order.order.other.additionalInfo}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrintButton clicked={() => handlePrint(order.order, order.cart)}>Drukuj</PrintButton>
    </>
  );
};

export default OrderPrint;