import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clock } from 'react-feather';
import StatusTag from '../StatusTag';

const OrderItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 10px 15px;
  border-bottom: solid 1px #dbdbdb;
  cursor: pointer;
  transition: all .2s ease-in-out;
  background-color: ${({ theme, isNew }) => isNew ? '#4ade80' : theme.backgroundColor.primary};

  &:hover{
    background-color: ${({ theme }) => theme.backgroundColor.secondary};
  }
`;

const OrderItemLeftCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 34%;
  width: 34%;
  justify-content: space-between;
`;

const OrderItemNumber = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const OrderItemTime = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  display:flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
`;

const OrderItemRealization = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
`;

const OrderItemName = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 500;
`;

const OrderItemPhone = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const OrderItemRightCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 71%;
  width: 71%;
`;

const OrderListItem = (props) => {
  const {
    orderNumber,
    orderStatus,
    handleOrderClicked,
    realizationType,
    orderTime,
    userName,
    userPhone
  } = props;

  const realizationTypes = {
    'pickup': 'Odbiór osobisty',
    'delivery': 'Dostawa'
  }

  return (
    <OrderItem onClick={handleOrderClicked} isNew={orderStatus === 'new'}>
      <OrderItemLeftCol>
        <OrderItemNumber>#{orderNumber}</OrderItemNumber>
        <OrderItemTime>
          <Clock size={13} strokeWidth={3} />
          {`${new Date(orderTime).getHours()}:${new Date(orderTime).getMinutes() < 10 ? (`0${  new Date(orderTime).getMinutes()}`) : new Date(orderTime).getMinutes()}`}
        </OrderItemTime>
        <StatusTag status={orderStatus} />
      </OrderItemLeftCol>
      <OrderItemRightCol>
        <OrderItemRealization>{realizationTypes[realizationType]}</OrderItemRealization>
        <OrderItemName>{userName}</OrderItemName>
        <OrderItemPhone>{userPhone}</OrderItemPhone>
      </OrderItemRightCol>
    </OrderItem>
  )
};

OrderListItem.propTypes = {
  orderNumber: PropTypes.number.isRequired,
  orderStatus: PropTypes.string.isRequired,
  realizationType: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userPhone: PropTypes.string.isRequired,
  orderTime: PropTypes.string.isRequired,
  handleOrderClicked: PropTypes.func.isRequired
}

export default OrderListItem;
