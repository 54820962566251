import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getRestaurantInfoStart = () => ({
  type: actionTypes.GET_RESTAURANT_INFO_START
});

export const getRestaurantInfoSuccess = (data) => ({
  type: actionTypes.GET_RESTAURANT_INFO_SUCCESS,
  data: data
});

export const getRestaurantInfoFail = (error) => ({
  type: actionTypes.GET_RESTAURANT_INFO_FAIL,
  error: error
});

export const getRestaurantInfo = (restaurantId) => dispatch => {
  dispatch(getRestaurantInfoStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/restaurantInfo/${restaurantId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(getRestaurantInfoSuccess(res.data.restaurantInfo));
    })
    .catch(err => {
      dispatch(getRestaurantInfoFail(err.response.data.message));
    })
};

// update restaurant Info
export const updateRestaurantInfoStart = () => ({
  type: actionTypes.UPDATE_RESTAURANT_INFO_START
})

export const updateRestaurantInfoSuccess = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_INFO_SUCCESS,
  data: data
})

export const updateRestaurantInfoFail = (error) => ({
  type: actionTypes.UPDATE_RESTAURANT_INFO_FAIL,
  error: error
})

export const updateRestaurantInfo = (restaurantInfoId, desc, localization, hours, deliverymin, deliveryprice, packageprice, phone, email) => dispatch => {
  dispatch(updateRestaurantInfoStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/restaurantInfo/${restaurantInfoId}`, {
    desc, 
    localization, 
    hours, 
    deliverymin,
    deliveryprice, 
    packageprice, 
    phone, 
    email
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(updateRestaurantInfoSuccess(res.data.restaurantInfo));
    })
    .catch(err => {
      dispatch(updateRestaurantInfoFail(err.response.data.message));
    })
};