import axios from 'axios';
import * as actionTypes from './actionTypes';

// add Items Option Group
export const addItemsOptionGroupStart = () => ({
  type: actionTypes.ADD_ITEMS_OPTION_GROUP_START
});

export const addItemsOptionGroupSuccess = (data) => ({
  type: actionTypes.ADD_ITEMS_OPTION_GROUP_SUCCESS,
  data: data
});

export const addItemsOptionGroupFail = (error) => ({
  type: actionTypes.ADD_ITEMS_OPTION_GROUP_FAIL,
  error: error
});

export const addItemsOptionGroup = (
  name,
  required,
  multiSelect,
  itemId,
  restaurantId
) => dispatch => {
  dispatch(addItemsOptionGroupStart());
  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/admin/itemOptionsGroup`, {
    name,
    required,
    multiSelect,
    itemId,
    restaurantId
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(addItemsOptionGroupSuccess(res.data));
    })
    .catch(err => {
      dispatch(addItemsOptionGroupFail(err.response.data.message));
    })
}

// get Items Option Groups
export const getItemsOptionGroupsStart = () => ({
  type: actionTypes.GET_ITEMS_OPTION_GROUP_START
});

export const getItemsOptionGroupsSuccess = (data) => ({
  type: actionTypes.GET_ITEMS_OPTION_GROUP_SUCCESS,
  data: data
});

export const getItemsOptionGroupsFail = (error) => ({
  type: actionTypes.GET_ITEMS_OPTION_GROUP_FAIL,
  error: error
});

export const getItemsOptionGroups = (restaurantId) => dispatch => {
  dispatch(getItemsOptionGroupsStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/itemOptionsGroup/${restaurantId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(getItemsOptionGroupsSuccess(res.data.data));
    })
    .catch(err => {
      console.log(err.response.data.message);
      dispatch(getItemsOptionGroupsFail(err.response.data.message));
    })
};

// delete Items Option Group
export const deleteItemsOptionGroupStart = () => ({
  type: actionTypes.DELETE_ITEMS_OPTION_GROUP_START
});

export const deleteItemsOptionGroupSuccess = (data) => ({
  type: actionTypes.DELETE_ITEMS_OPTION_GROUP_SUCCESS,
  data: data
});

export const deleteItemsOptionGroupFail = (error) => ({
  type: actionTypes.DELETE_ITEMS_OPTION_GROUP_FAIL,
  error: error
});

export const deleteItemsOptionGroup = (optionsGroupId) => dispatch => {
  dispatch(deleteItemsOptionGroupStart());

  const token = localStorage.getItem('token');
  axios.delete(`${process.env.REACT_APP_API}/admin/itemOptionsGroup/${optionsGroupId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(deleteItemsOptionGroupSuccess(res.data.itemsOptionGroupId));
    })
    .catch(err => {
      dispatch(deleteItemsOptionGroupFail(err.response.data.message));
    })
};


export const updateItemsOptionGroupStart = () => ({
  type: actionTypes.UPDATE_ITEMS_OPTION_GROUP_START,
});

export const updateItemsOptionGroupSuccess = (data) => ({
  type: actionTypes.UPDATE_ITEMS_OPTION_GROUP_SUCCESS,
  data: data
});

export const updateItemsOptionGroupFail = (error) => ({
  type: actionTypes.UPDATE_ITEMS_OPTION_GROUP_FAIL,
  error: error
});

export const updateItemsOptionGroup = (id, name, required, multiSelect) => dispatch => {
  dispatch(updateItemsOptionGroupStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/itemOptionsGroup/${id}`, {
    name,
    required,
    multiSelect

  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(updateItemsOptionGroupSuccess(res.data.itemsOptionGroup));
    })
    .catch(err => {
      dispatch(updateItemsOptionGroupFail(err.response.data.message));
    })
};
