import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trash2, PlusCircle } from 'react-feather';
import styled from 'styled-components';
import Modal from '../../../components/UI/Modal/Modal';
import useModal from '../../../hooks/useModal/useModal';
import * as actions from '../../../state/actions/index';

import AddPolygonDeliveryArea from '../../../components/Forms/AddPolygonDeliveryArea/AddPolygonDeliveryArea';

import Button from '../../../components/UI/Button/Button';
import DropdownDeliveryType from '../../../components/UI/Dropdown/DropdownDeliveryType';

const StyledManageContainer = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 6px 15px;
  gap: 30px;
  align-items: center;
  touch-action: none;
  width: 100%;
`;

const ItemRowData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2rem;
`;

const ItemRowManage = styled.div`

`;

const ItemCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ItemColDataTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const ItemColDataContent = styled.p`
  font-size: 12px;
`;

const PlusCircleIcon = styled(PlusCircle)`
  margin-right: 8px;
`;


const ManageDeliveryAreaPolygon = (props) => {
  const {
    deliveryArea,
    deleteDeliveryAreaItem
  } = props;

  const [isShowingModal, toggleModal] = useModal();

  const handleAddPolygon = () => {
    toggleModal();
  }

  const handleDeleteZone = (deliveryAreaItemId) => {
    deleteDeliveryAreaItem(deliveryAreaItemId);
  }

  return (
    <StyledManageContainer>
      <DropdownDeliveryType />
      {
        deliveryArea.deliveryAreaItems.length < 1
          ?
          'Brak stref dostaw'
          :
          (
            deliveryArea.deliveryAreaItems.map((item) =>
              <ItemRow key={item._id}>
                <ItemRowData>
                  <ItemCol>
                    <ItemColDataTitle>
                      Nazwa:
                    </ItemColDataTitle>
                    <ItemColDataContent>
                      {item.name}
                    </ItemColDataContent>
                  </ItemCol>
                  <ItemCol>
                    <ItemColDataTitle>
                      Cena:
                    </ItemColDataTitle>
                    <ItemColDataContent>
                      {item.price.toFixed(2)} zł
                    </ItemColDataContent>
                  </ItemCol>
                </ItemRowData>
                <ItemRowManage>
                  <Button variant="alert" clicked={() => handleDeleteZone(item._id)}>
                    <Trash2 size={16} />
                  </Button>
                </ItemRowManage>
              </ItemRow>
            )
          )
      }
      <div>
        <Button clicked={() => handleAddPolygon()}>
          <PlusCircleIcon /> Dodaj nowa strefę
        </Button>
      </div>
      <Modal
        show={isShowingModal}
        clicked={toggleModal}
      >
        <AddPolygonDeliveryArea deliveryAreaId={deliveryArea._id} />
      </Modal>
    </StyledManageContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  deleteDeliveryAreaItem: (deliveryAreaItemId) => dispatch(actions.deleteDeliveryAreaItem(deliveryAreaItemId))
});

ManageDeliveryAreaPolygon.propTypes = {
  deleteDeliveryAreaItem: PropTypes.func.isRequired,
  deliveryArea:
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      deliveryAreaItems: PropTypes.arrayOf(
        PropTypes.shape({
          radius: PropTypes.number
        })
      )
    }),
};

ManageDeliveryAreaPolygon.defaultProps = {
  deliveryArea: {}
};

export default connect(null, mapDispatchToProps)(ManageDeliveryAreaPolygon);
