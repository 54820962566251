import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as actions from '../../../state/actions/index';
import Button from '../../../components/UI/Button/Button';
import DeliveryAreaCircle from './DeliveryAreaCircle';
import DeliveryAreaPolygon from './DeliveryAreaPolygon';
import Loading from '../../../components/UI/Loading/Loading';

const DeliveryArea = (props) => {
  const { deliveryArea } = props;
  return (
    deliveryArea.type === "circle"
      ?
      <DeliveryAreaCircle deliveryArea={deliveryArea} />
      :
      <DeliveryAreaPolygon deliveryArea={deliveryArea} />
  );
}

DeliveryArea.propTypes = {
  deliveryArea: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

const Delivery = (props) => {
  const {
    activeRestaurant,
    getDeliveryAreas,
    deliveryArea,
    addDeliveryArea,
    getActiveRestaurantCords,
    isLoadingActiveRestaurant,
    isLoadingDeliveryArea
  } = props;

  useEffect(() => {
    getDeliveryAreas(activeRestaurant._id);
    getActiveRestaurantCords(activeRestaurant.placeId);

    const token = localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API}/admin/checkDelivery`, {
      placeId: 'ChIJDc9sB2kWA0cR9KN57IalGXM',
      restaurantId: activeRestaurant._id
    },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [activeRestaurant])

  return (
    <div>
      {
        !isLoadingActiveRestaurant && !isLoadingDeliveryArea
          ?
          <div>
            <h1>Dostawy</h1>
            {
              !deliveryArea.type
                ?
                <Button marginX={1} clicked={() => addDeliveryArea(activeRestaurant._id)}>
                  Konfiguruj dostawy
                </Button>
                :
                <DeliveryArea deliveryArea={deliveryArea} />
            }
          </div>
          :
          <Loading />
      }
    </div>
  )
};

const mapStateToProps = state => ({
  activeRestaurant: state.restaurants.activeRestaurant,
  deliveryArea: state.deliveryAreas.deliveryArea,
  isLoadingDeliveryArea: state.deliveryAreas.isLoading,
  isLoadingActiveRestaurant: state.restaurants.isLoadingActiveRestaurant
});

const mapDispatchToProps = dispatch => ({
  addDeliveryArea: (restaurantId) => dispatch(actions.addDeliveryArea(restaurantId)),
  getDeliveryAreas: (restaurantId) => dispatch(actions.getDeliveryAreas(restaurantId)),
  getActiveRestaurantCords: (placeId) => dispatch(actions.getActiveRestaurantCords(placeId)),
});

Delivery.propTypes = {
  isLoadingDeliveryArea: PropTypes.bool.isRequired,
  isLoadingActiveRestaurant: PropTypes.bool.isRequired,
  addDeliveryArea: PropTypes.func.isRequired,
  getActiveRestaurantCords: PropTypes.func.isRequired,
  getDeliveryAreas: PropTypes.func.isRequired,
  activeRestaurant: PropTypes.shape({
    _id: PropTypes.string,
    placeId: PropTypes.string
  }),
  deliveryArea: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string
  })
};

Delivery.defaultProps = {
  activeRestaurant: {},
  deliveryArea: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
