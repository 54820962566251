import React from 'react';
import SettingsInfoForm from '../../../components/Forms/SettingsInfoForm/SettingsInfoForm';

const Info = () => (
  <div>
    <h1>Informacje o Lokalu</h1>

    <SettingsInfoForm />
  </div>
);

export default Info;
