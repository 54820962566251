import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  order: {},
  isLoadingOrder: false,
  getOrderError: null,
  orders: [],
  isLoadingOrders: false,
  getOrdersError: null,

  isUpdatingOrderStatus: false,
  updateOrderStatusError: null,

  isLoadingCart: false,
  cartError: null,
  cart: {},
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDER_START:
      return {
        ...state,
        isLoadingOrder: true
      }
    case actionTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingOrder: false,
        order: action.data
      }
    case actionTypes.GET_ORDER_FAIL:
      return {
        ...state,
        isLoadingOrder: false,
        getOrderError: action.error
      }
    case actionTypes.GET_ORDERS_START:
      return {
        ...state,
        isLoadingOrders: true
      }
    case actionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        orders: action.data
      }
    case actionTypes.GET_ORDERS_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        getOrdersError: action.error
      }
    case actionTypes.UPDATE_ORDERS:
      if (state.orders.filter(item => item._id === action.data._id).length === 0) {
        return {
          ...state,
          orders: [ ...state.orders, action.data]
        }
      }
      return {
        ...state
      }
    case actionTypes.UPDATE_ORDER_STATUS_START:
      return {
        ...state,
        isUpdatingOrderStatus: true
      }
    case actionTypes.UPDATE_ORDER_STATUS_SUCCESS:
      if (state.orders.filter(item => item._id === action.data._id).length > 0) {
        return {
          ...state,
          orders: state.orders.map(
            (order) => order._id === action.data._id ? { ...order, status: action.data.status } : order
          ),
          order: action.data
        }
      }
      return {
        ...state
      }
    case actionTypes.UPDATE_ORDER_STATUS_FAIL:
      return {
        ...state,
        isUpdatingOrderStatus: false,
        updateOrderStatusError: action.error
      }
    case actionTypes.GET_CART_START:
      return {
        ...state,
        isLoadingCart: true
      }
    case actionTypes.GET_CART_SUCCESS:
      return {
        ...state,
        cart: action.data.cart,
        isLoadingCart: false
      }
    case actionTypes.GET_CART_FAIL:
      return {
        ...state,
        cart: {},
        cartError: action.error,
        isLoadingCart: false
      }
    default:
      return state;
  }
}

export default reducer;
