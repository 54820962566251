import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';
import Modal from '../../UI/Modal/Modal';
import useModal from '../../../hooks/useModal/useModal';
import Loading from '../../UI/Loading/Loading';
import * as actions from '../../../state/actions/index';

import MediaPicker from '../../../containers/RestaurantSettings/MediaPicker';

import addImage from '../../../assets/images/addimage.png';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledInput = styled.input`
  background-color: #16283f;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const AddImage = styled.img`
  max-width: 70px;
  max-height: 70px;
  border-radius:50%;
  cursor: pointer;
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const EditMenuCategoryForm = (props) => {
  const { itemCategoryId, errorResponse } = props;
  const { updateItemCategory, updateItemCategoryErrorResponse, getItemCategory, getItemCategoryError, getItemCategoryIsLoading, itemCategory } = props;

  const [isShowingModal, toggleModal] = useModal();
  const [choosenImage, setChoosenImage] = useState();
  const [imageError, setImageError] = useState(false);
  const { register, reset, errors, handleSubmit } = useForm({
    defaultValues: { name: itemCategory.name }
  });

  useEffect(() => {
    getItemCategory(itemCategoryId);
  }, [])

  useEffect(() => {
    itemCategory
      ?
      setChoosenImage(itemCategory.image)
      :
      null
  }, [itemCategory]);

  useEffect(() => {
    reset(itemCategory);
  }, [itemCategory]);

  const handleImageModal = () => {
    toggleModal();
  }

  const handleImageChange = (img) => {
    setChoosenImage(img.name);
    toggleModal();
  }

  const checkImageError = () => {
    if (choosenImage === undefined) {
      setImageError(true);
    } else {
      setImageError(false);
    }
  }

  const onSubmit = data => {
    if (choosenImage === undefined) {
      setImageError(true);
    } else {
      setImageError(false);
      updateItemCategory(itemCategory._id, data.name, choosenImage);
    }
  }

  return (
    <>
      {!getItemCategoryIsLoading && Object.keys(itemCategory).length !== 0
        ?
        (
          <>
            <StyledFormHeader>Edytuj Kategorię</StyledFormHeader>
            <button type="button" onClick={handleImageModal}>
              {choosenImage
                ?
                <AddImage src={itemCategory.image ? `${process.env.REACT_APP_API}/upload/${choosenImage}` : addImage} alt="Wybierz Zdjęcie" />
                :
                <Loading />
              }
            </button>
            {imageError ? <span>OBRAZ JEST WYMAGANY</span> : null}
            <StyledForm onSubmit={handleSubmit(onSubmit, checkImageError)}>
              <InputField>
                <StyledInput
                  name="name"
                  placeholder="Nazwa kategorii"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Nazwa jest wymagana'
                    }
                  })}
                />
                <span>{errors.name && <StyledAlertIcon />}</span>
                <div>{errors.name && errors.name.message}</div>
              </InputField>
              <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
              <StyledErrorBox err={updateItemCategoryErrorResponse}><AlertTriangle />{updateItemCategoryErrorResponse}</StyledErrorBox>
              <StyledInput type='submit' value='Zaktualizuj Kategorię' />
              {getItemCategoryError ? (<div>{getItemCategoryError}</div>) : null}
            </StyledForm>
          </>
        )
        :
        <Loading />
      }
      <Modal
        show={isShowingModal}
        clicked={toggleModal}
      >
        <MediaPicker handleImageChange={handleImageChange} />
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  errorResponse: state.restaurants.error,
  getItemCategoryError: state.itemCategories.getItemCategoryError,
  getItemCategoryIsLoading: state.itemCategories.getItemCategoryIsLoading,
  getItemCategory: state.itemCategories.getItemCategory,
  itemCategory: state.itemCategories.itemCategory,
  updateItemCategoryErrorResponse: state.itemCategories.updateItemCategoryErrorResponse
});

const mapDispatchToProps = dispatch => ({
  updateItemCategory: (id, name, image) => dispatch(actions.updateItemCategory(id, name, image)),
  getItemCategory: (itemCategoryId) => dispatch(actions.getItemCategory(itemCategoryId))
});

EditMenuCategoryForm.propTypes = {
  itemCategory: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string
  }),
  updateItemCategory: PropTypes.func.isRequired,
  getItemCategory: PropTypes.func.isRequired,
  getItemCategoryIsLoading: PropTypes.bool.isRequired,
  getItemCategoryError: PropTypes.string,
  errorResponse: PropTypes.string,
  updateItemCategoryErrorResponse: PropTypes.string,
  itemCategoryId: PropTypes.string.isRequired
};

EditMenuCategoryForm.defaultProps = {
  itemCategory: {},
  errorResponse: null,
  getItemCategoryError: null,
  updateItemCategoryErrorResponse: null
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMenuCategoryForm);
