import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sound from '../../sounds/confirmation.ogg';
import usePrevious from '../../hooks/usePrevious/usePrevious';

const newOrderSound = new Audio(sound);

const NotificationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, count }) => count > 0 ? '#4ade80' : theme.backgroundColor.primary};
  padding: 15px;
  aspect-ratio: 1/1;
  font-weight: 600;
  line-height: 10px;
  color: ${({ theme, count }) => count > 0 ? 'white' : theme.neutralColor.primary};
  border-radius: 10px;
`;

const Notification = ({ newOrdersCount }) => {

  const prevNewOrdersCount = usePrevious(newOrdersCount);

  useEffect(() => {
    if (prevNewOrdersCount < newOrdersCount) {
      newOrderSound.play();
    }
  }, [newOrdersCount]);

  return (
    <NotificationBox count={newOrdersCount}>
      {newOrdersCount}
    </NotificationBox>
  )
};

Notification.propTypes = {
  newOrdersCount: PropTypes.number.isRequired
}

export default Notification;
