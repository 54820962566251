/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";

const StyledDatePicker = styled.div`
  .react-datepicker-wrapper {
    width: auto;
  }
  input{
    text-align: center;
    padding: 5px 10px;
    font-weight: 600;
    font-family: 'Montserrat';
  }
`;

const OrderDatePicker = (props) => {
  const { onDateChange } = props;
  const [startDate, setStartDate] = useState(new Date());
  return (
    <StyledDatePicker>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          console.log(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1}`);
          onDateChange(date);
        }} />
    </StyledDatePicker>
  );
};

export default OrderDatePicker;
