import axios from 'axios';
import * as actionTypes from './actionTypes';

// get single Menu Item
export const getHoursStart = () => ({
  type: actionTypes.GET_HOURS_START
});

export const getHoursSuccess = (data) => ({
  type: actionTypes.GET_HOURS_SUCCESS,
  data: data
});

export const getHoursFail = (error) => ({
  type: actionTypes.GET_HOURS_FAIL,
  error: error
});

export const getHours = (restaurantId) => dispatch => {
  dispatch(getHoursStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/hours/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getHoursSuccess(res.data));
    })
    .catch(err => {
      dispatch(getHoursFail(err.response.data.message));
    })
};

// update menu item
export const updateHoursStart = () => ({
  type: actionTypes.UPDATE_MENU_ITEM_START
})

export const updateHoursSuccess = (data) => ({
  type: actionTypes.UPDATE_MENU_ITEM_SUCCESS,
  data: data
})

export const updateHoursFail = (error) => ({
  type: actionTypes.UPDATE_MENU_ITEM_FAIL,
  error: error
})

export const updateHours = (hours) => dispatch => {
  dispatch(updateHoursStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/hours`, {
    hours
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(updateHoursSuccess(res.data));
    })
    .catch(err => {
      dispatch(updateHoursFail(err.response.data.message));
    })
}