import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Polygon, OverlayView } from "@react-google-maps/api";
import * as actions from '../../../state/actions/index';

const getCenter = (pointsArr) => {
  let sumLat = 0;
  let sumLng = 0;
  for (let i = 0; i < pointsArr.length; i++) {
    const point = pointsArr[i];
    const { lat: pointLat, lng: pointLng } = point;
    sumLat += pointLat;
    sumLng += pointLng;
  }
  return { lat: sumLat / pointsArr.length, lng: sumLng / pointsArr.length };
}

const PolygonZone = (props) => {
  const {
    polygonCords,
    name,
    updatePolygonZone,
    deliveryAreaItemId
  } = props;

  const [polygonCenter, setPolygonCenter] = useState(getCenter(polygonCords));

  const [areaPoints, setAreaPoints] = useState(polygonCords);

  useEffect(() => {
    setPolygonCenter(getCenter(areaPoints));
  }, [areaPoints]);

  const polygonRef = useRef(null);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() })
        );
      setAreaPoints(nextPath);
      updatePolygonZone(deliveryAreaItemId, nextPath);
    }
  }, [areaPoints]);

  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
    },
    [onEdit]
  );

  return (
    <>
      <OverlayView
        position={polygonCenter}
        mapPaneName="floatPane"
      >
        <div
          style={{
            padding: `7px 12px`,
            fontWeight: '600',
            textTransform: 'uppercase',
            fontSize: '14px',
            letterSpacing: '2px',
            color: `white`,
            transform: `translate(-50%, -50%)`,
            borderRadius: '4px',
            marginTop: `-25%`
          }}
        >
          {name}
        </div>
      </OverlayView>
      {
        areaPoints.length > 0
          ?
          <Polygon
            path={areaPoints}
            draggable
            editable
            onMouseUp={onEdit}
            onLoad={onLoad}
          />
          :
          null
      }
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  updatePolygonZone: (deliveryAreaItemId, newPath) => dispatch(actions.updatePolygonZone(deliveryAreaItemId, newPath))
});

PolygonZone.propTypes = {
  deliveryAreaItemId: PropTypes.string,
  updatePolygonZone: PropTypes.func.isRequired,
  name: PropTypes.string,
  polygonCords: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }))
}

PolygonZone.defaultProps = {
  name: null,
  polygonCords: [],
  deliveryAreaItemId: null
}

export default connect(null, mapDispatchToProps)(PolygonZone);
