import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loading from '../../../components/UI/Loading/Loading';
import Button from '../../../components/UI/Button/Button';
import * as actions from '../../../state/actions/index';

const DaysContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Day = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 25px;
  font-weight: 600;
  border-bottom: solid 1px gray;
`;

const DayName = styled.div`
  display: flex;
  flex-basis: 40%;
`;

const DayTime = styled.div`
  display: flex;
  flex-basis: 30%;
  justify-content: center;
  align-items: center;
`;

const DayTimeValue = styled.div`
  display: flex;
  flex-basis: 30%;
  justify-content: center;
`;

const ButtonRow = styled.div`
  display: flex;
  margin: 15px 0px;
  justify-content: center;
`;

const Hours = (props) => {
  const { hours, getHours, isLoadingHours, updateHours } = props;

  const timeStep = 15 * 60000;
  const timeMin = 0;
  const timeMax = 86400000;


  const [updatedHours, setUpdatedHours] = useState([]);

  const days = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];

  useEffect(() => {
    getHours(window.localStorage.getItem('activeRestaurant'));
  }, []);

  useEffect(() => {
    setUpdatedHours(hours);
  }, [hours]);

  const handleSaveHours = (newHours) => {
    updateHours(newHours);
  }

  const msToHours = (timeVal) => {
    const hrs = Math.floor(timeVal / 3600000);
    if(hrs<10){
      return `0${  hrs}`;
    }
    return hrs;
  };

  const msToMinutes = (timeVal) => {
    const toMinutes = timeVal % 3600000;
    const mins = Math.floor(toMinutes / 60000);

    if(mins<10){
      return `0${ mins}`;
    }
    return mins;
  };

  const handlePlus = (id, type) => {
    let updatedHoursCopy;

    if(type === 'open'){
      const currentOpenTime = updatedHours.filter(x => x._id === id ? x : null)[0].open;
      if(currentOpenTime + timeStep > timeMax){
        updatedHoursCopy = updatedHours.map(x => (x._id === id ? {...x, open: timeMin} : x));
      } else {
        updatedHoursCopy = updatedHours.map(x => (x._id === id ? {...x, open: x.open + timeStep} : x));
      }
    } else {
      const currentCloseTime = updatedHours.filter(x => x._id === id ? x : null)[0].close;
      if(currentCloseTime + timeStep > timeMax){
        updatedHoursCopy = updatedHours.map(x => (x._id === id ? {...x, close: timeMin} : x));
      } else {
        updatedHoursCopy = updatedHours.map(x => (x._id === id ? {...x, close: x.close + timeStep} : x));
      }
    }
    setUpdatedHours(updatedHoursCopy);
  }

  const handleMinus = (id, type) => {
    let updatedHoursCopy;

    if(type === 'open'){
      const currentOpenTime = updatedHours.filter(x => x._id === id ? x : null)[0].open;
      if(currentOpenTime - timeStep < timeMin){
        updatedHoursCopy = updatedHours.map(x => (x._id === id ? {...x, open: timeMax} : x));
      } else {
        updatedHoursCopy = updatedHours.map(x => (x._id === id ? {...x, open: x.open - timeStep} : x));
      }
    } else {
      const currentCloseTime = updatedHours.filter(x => x._id === id ? x : null)[0].close;
      if(currentCloseTime - timeStep < timeMin){
        updatedHoursCopy = updatedHours.map(x => (x._id === id ? {...x, close: timeMax} : x));
      } else {
        updatedHoursCopy = updatedHours.map(x => (x._id === id ? {...x, close: x.close - timeStep} : x));
      }
    }
    setUpdatedHours(updatedHoursCopy);
  }

  return(
  <div>
    <h1>Godziny Otwarcia</h1>


    <DaysContainer>
      { 
        !isLoadingHours && updatedHours.length > 0
        ?
          <>
            {updatedHours.map(hour => (
              <Day key={hour._id}>
                <DayName>{days[hour.day]}</DayName>
                <DayTime>
                  {/* <TimePicker 
                    hours={msToHours(hour.open)} 
                    minutes={msToMinutes(hour.open)} 
                    onPlus={() => handlePlus(hour._id)} 
                    onMinus={() => handleMinus(hour._id)}/> 
                  */}
                  <Button clicked={() => handleMinus(hour._id, 'open')}>-</Button>
                  <DayTimeValue>{msToHours(hour.open)} : {msToMinutes(hour.open)}</DayTimeValue>
                  <Button clicked={() => handlePlus(hour._id, 'open')}>+</Button>
                </DayTime>
                <DayTime>
                  <Button clicked={() => handleMinus(hour._id, 'close')}>-</Button>
                  <DayTimeValue>{msToHours(hour.close)} : {msToMinutes(hour.close)}</DayTimeValue>
                  <Button clicked={() => handlePlus(hour._id, 'close')}>+</Button>
                </DayTime>
              </Day>
            ))}
            <ButtonRow>
              <Button clicked={() => handleSaveHours(updatedHours)}>
                Zapisz
              </Button>
            </ButtonRow>
          </>
        :
        <Loading />
      }
    </DaysContainer>
  </div>
  )};

const mapStateToProps = state => ({
  hours: state.hours.hours,
  isLoadingHours: state.hours.isLoadingHours
});

const mapDispatchToProps = dispatch => ({
  getHours: (restaurantId) => dispatch(actions.getHours(restaurantId)),
  updateHours: (updatedHours) => dispatch(actions.updateHours(updatedHours))
});

Hours.propTypes = {
  getHours: PropTypes.func.isRequired,
  updateHours: PropTypes.func.isRequired,
  isLoadingHours: PropTypes.bool.isRequired,
  hours: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    day: PropTypes.number,
    open: PropTypes.number,
    close: PropTypes.number
  }))
}

Hours.defaultProps = {
  hours: []
}

export default connect(mapStateToProps, mapDispatchToProps)(Hours);
