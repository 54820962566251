import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Trash2, Edit, PlusCircle, List } from 'react-feather';
import * as actions from '../../../../state/actions/index';
import ConfirmationModal from '../../../../components/UI/ConfirmationModal/ConfirmationModal';
import useConfirmationModal from '../../../../hooks/UseConfirmationModal/useConfirmationModal';
import Modal from '../../../../components/UI/Modal/Modal';
import useModal from '../../../../hooks/useModal/useModal';
import Button from '../../../../components/UI/Button/Button';

import AddItemsOptionGroupForm from '../../../../components/Forms/AddItemsOptionGroupForm/AddItemsOptionGroupForm';
import EditItemsOptionGroupForm from '../../../../components/Forms/EditItemsOptionGroupForm/EditItemsOptionGroupForm';
import ItemOptionsList from './ItemOptionsList';

const ItemOptionsGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemOptionsGroupListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
`;

const ItemOptionsGroupListItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  margin-bottom: 10px;
  padding: 10px;
  gap: 30px;
  align-items: center;
  touch-action: none;
`;

const ItemOptionsGroupListItemData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 15px;
`;

const ItemOptionsGroupListItemName = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

const ItemOptionsGroupListItemManage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlusCircleIcon = styled(PlusCircle)`
  margin-right: 8px;
`;

const ItemOptionGroupList = (props) => {
  const {
    itemId,
    itemsOptionGroups,
    deleteItemsOptionsGroup
  } = props;

  const [isShowingConfirmationModal, toggleConfirmationModal] = useConfirmationModal();
  const [itemsOptionGroupId, setItemsOptionGroupId] = useState();
  const [itemsOptionGroup, setItemsOptionGroup] = useState();
  const [flteredItemsOptionGroups, setFilteredItemsOptionGroup] = useState(() => itemsOptionGroups.filter((item) => item.item === itemId ? item : null));
  const [isShowingAddItemModal, toggleAddItemModal] = useModal();
  const [isShowingEditItemModal, toggleEditItemModal] = useModal();
  const [isShowingOptionsListModal, toggleOptionsListModal] = useModal();

  useEffect(() => {
    setFilteredItemsOptionGroup(() => itemsOptionGroups.filter((item) =>
      item.item === itemId ? item : null
    ))
  }, [itemsOptionGroups])

  const handleAddItemClicked = () => {
    toggleAddItemModal();
  }

  const handleEditItemClicked = (item) => {
    toggleEditItemModal();
    setItemsOptionGroupId(item._id);
    setItemsOptionGroup(item);
  }

  const handleDeleteItemClicked = (id) => {
    toggleConfirmationModal();
    setItemsOptionGroupId(id);
  }

  const handleConfirmDelete = (id) => {
    deleteItemsOptionsGroup(id);
    toggleConfirmationModal();
  }

  const handleShowOptionsClicked = (item) => {
    toggleOptionsListModal();
    setItemsOptionGroupId(item._id);
  }

  return (
    <ItemOptionsGroupContainer>
      <ItemOptionsGroupListContainer>

        {
          flteredItemsOptionGroups.length > 0
            ?
            (flteredItemsOptionGroups.map(item => (
              <ItemOptionsGroupListItemRow key={item._id}>
                <ItemOptionsGroupListItemData>
                  <ItemOptionsGroupListItemName>{item.name}</ItemOptionsGroupListItemName>
                  <ItemOptionsGroupListItemManage>
                    <Button clicked={() => handleShowOptionsClicked(item)} paddingX={8} marginX={5}>
                      <List size={16} />
                    </Button>
                    <Button clicked={() => handleEditItemClicked(item)} paddingX={8} marginX={5}>
                      <Edit size={16} />
                    </Button>
                    <Button clicked={() => handleDeleteItemClicked(item._id)} variant="alert" paddingX={8} marginX={5}>
                      <Trash2 size={16} />
                    </Button>
                  </ItemOptionsGroupListItemManage>
                </ItemOptionsGroupListItemData>
              </ItemOptionsGroupListItemRow>
            )))
            :
            <p>Brak grup opcji dla tego produktu</p>
        }
      </ItemOptionsGroupListContainer>
      <div>
        <Button clicked={() => handleAddItemClicked()}> <PlusCircleIcon />Dodaj Grupę Opcji</Button>
      </div>

      <Modal
        show={isShowingAddItemModal}
        clicked={toggleAddItemModal}
      >
        <AddItemsOptionGroupForm itemId={itemId} />
      </Modal>

      <Modal
        show={isShowingEditItemModal}
        clicked={toggleEditItemModal}
      >
        <EditItemsOptionGroupForm itemsOptionGroup={itemsOptionGroup} />
      </Modal>

      <Modal
        show={isShowingOptionsListModal}
        clicked={toggleOptionsListModal}
      >
        <ItemOptionsList itemOptionsGroupId={itemsOptionGroupId} />
      </Modal>

      <ConfirmationModal
        show={isShowingConfirmationModal}
        clicked={toggleConfirmationModal}
        title="Potwierdź operację"
        text="Czy chcesz usunąć grupę opcji?"
        onConfirm={() => handleConfirmDelete(itemsOptionGroupId)}
      />
    </ItemOptionsGroupContainer>
  )
};

const mapStateToProps = state => ({
  itemsOptionGroups: state.itemsOptionGroups.optionsGroups,
  isLoadingItemsOptionGroups: state.itemsOptionGroups.isLoading,
});

const mapDispatchToProps = dispatch => ({
  deleteItemsOptionsGroup: (id) => dispatch(actions.deleteItemsOptionGroup(id))
});

ItemOptionGroupList.propTypes = {
  itemId: PropTypes.string.isRequired,
  deleteItemsOptionsGroup: PropTypes.func.isRequired,
  itemsOptionGroups: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  })),
}

ItemOptionGroupList.defaultProps = {
  itemsOptionGroups: [],
}


export default connect(mapStateToProps, mapDispatchToProps)(ItemOptionGroupList);
