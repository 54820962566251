/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { io } from "socket.io-client";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Meh } from 'react-feather';
import * as actions from '../../state/actions/index';
import DatePicker from '../../components/Orders/OrderDatePicker';
import OrderListItem from '../../components/Orders/OrderList/OrderListItem';
import Notification from '../../components/Orders/Notification';
import Loading from '../../components/UI/Loading/Loading';
import OrderData from '../../components/Orders/OrderData/OrderData';

const OrdersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: -30px;
`;

const OrdersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  width: 30%;
`;

const OrderListContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 195px);

&::-webkit-scrollbar {
  width: 0.7em;
}

&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

&::-webkit-scrollbar-thumb {
  background-color: #64748b;
  outline: 0px solid #334155;
}

&input::-webkit-outer-spin-button,
&input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
`;

const OrderListHeader = styled.div`
  background-color: ${({ theme }) => theme.accentColor.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;

  & .header-row {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 1rem;
  }

  & h2{
    color: white;
  }
`;

const OrderContainer = styled.div`
  display: flex;
  flex-basis: 70%;
  width: 70%;
  padding: 10px;
`;

const NoOrders = styled.div`
   width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  color: #cbd5e1;

  & h3 {
    color: #cbd5e1;
  }
`;

const OrdersList = (props) => {
  const { orders, handleOrderClicked } = props;

  const filteredOrdersCurrRestaurant = orders.filter(
    order => (
      order.restaurant === localStorage.getItem('activeRestaurant') ? order : null
    )
  );
  
  const filteredOrders = filteredOrdersCurrRestaurant.filter(
    order => (
      (order.status === 'failedPayment' || order.status === 'awaitingPayment') ? null : order
    )
  );

  if (filteredOrders.length === 0) {
    return (
      <NoOrders>
        <Meh size={52} />
        <h3>Na razie brak zamówień</h3>
      </NoOrders>
    )
  }
  return (
    [...orders]
      .filter(item => ((item.status === 'awaitingPayment' || item.status === 'failedPayment') ? null : item))
      .sort((a, b) => (a.orderDate < b.orderDate) ? 1 : -1)
      .map((item) => (
        <OrderListItem
          key={item._id}
          orderId={item._id}
          orderNumber={item.orderNumber}
          orderStatus={item.status}
          orderTime={item.orderDate}
          realizationType={item.realizationType}
          userName={`${item.userData.name} ${item.userData.surname}`}
          userPhone={item.userData.phone}
          handleOrderClicked={() => handleOrderClicked(item._id)}
        />
      ))
  )
}

const Orders = (props) => {
  const {
    getOrder,
    getOrders,
    order,
    orders,
    isLoadingOrder,
    isLoadingOrders,
    updateOrders,
    isLoadingActiveRestaurant,
    activeRestaurant
  } = props;

  const [activeDate, setActiveDate] = useState(new Date());
  const [newOrdersCount, setNewOrdersCount] = useState(0);

  const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1}`;

  useEffect(() => {
    getOrders(formatDate(activeDate));
  }, [activeDate, activeRestaurant]);

  useEffect(() => {
    setNewOrdersCount(orders.filter(item => item.status === 'new' ? item : null).length);
  }, [orders])

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API}`);

    socket.emit('create', localStorage.getItem('orderId'));
    socket.on('NewOrder', data => {
      console.log('ioPing: ', data);
      updateOrders(data);
    });
  }, []);

  const handleDateChange = (newDate) => {
    console.log('date changed', newDate);
    setActiveDate(newDate)
  }

  const handleOrderClicked = (orderId) => {
    console.log(orderId);
    getOrder(orderId);
  }

  return (
    <OrdersContainer>
      <OrdersListContainer>
        <OrderListHeader>
          <div className='header-row'>
            <h2>Zamówienia</h2>
            <Notification newOrdersCount={newOrdersCount} />
          </div>
          <DatePicker onDateChange={handleDateChange} />
        </OrderListHeader>
        <OrderListContainer>
          {
            isLoadingOrders || isLoadingActiveRestaurant
              ?
              <Loading />
              :
              <OrdersList orders={orders} handleOrderClicked={handleOrderClicked} />
          }
        </OrderListContainer>
      </OrdersListContainer>
      <OrderContainer>
        {
          isLoadingOrder
            ?
            <Loading />
            :
            <OrderData activeOrder={order} />
        }
      </OrderContainer>
    </OrdersContainer>
  )
};

const mapStateToProps = state => ({
  isLoadingOrder: state.orders.isLoadingOrder,
  isLoadingOrders: state.orders.isLoadingOrders,
  order: state.orders.order,
  orders: state.orders.orders,
  isLoadingActiveRestaurant: state.restaurants.isLoadingActiveRestaurant,
  activeRestaurant: state.restaurants.activeRestaurant,
});

const mapDispatchToProps = dispatch => ({
  getOrder: (orderId) => dispatch(actions.getOrder(orderId)),
  getOrders: (date) => dispatch(actions.getOrders(date)),
  updateOrders: (data) => dispatch(actions.updateOrders(data))
});

Orders.propTypes = {
  getOrder: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  order: PropTypes.shape({
    _id: PropTypes.string
  }),
  orders: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string
  })),
  isLoadingOrder: PropTypes.bool.isRequired,
  isLoadingOrders: PropTypes.bool.isRequired,
  activeRestaurant: PropTypes.shape({
    _id: PropTypes.string
  }),
  isLoadingActiveRestaurant: PropTypes.bool.isRequired
}

Orders.defaultProps = {
  order: {},
  orders: [],
  activeRestaurant: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
