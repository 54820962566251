import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';
import Modal from '../../UI/Modal/Modal';
import useModal from '../../../hooks/useModal/useModal';
import Loading from '../../UI/Loading/Loading';
import * as actions from '../../../state/actions/index';

import MediaPicker from '../../../containers/RestaurantSettings/MediaPicker';

import addImage from '../../../assets/images/addimage.png';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledInput = styled.input`
  background-color: #16283f;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const AddImage = styled.img`
  max-width: 70px;
  max-height: 70px;
  border-radius:50%;
  cursor: pointer;
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const EditMenuItemForm = (props) => {
  const { menuItemId, errorResponse } = props;
  const { updateMenuItem, updateMenuItemErrorResponse, getMenuItem, getMenuItemError, getMenuItemIsLoading, menuItem } = props;

  const [isShowingModal, toggleModal] = useModal();
  const [choosenImage, setChoosenImage] = useState();
  const [imageError, setImageError] = useState(false);
  const { register, reset, errors, handleSubmit } = useForm({
    defaultValues: {
      name: menuItem.name,
      desc: menuItem.desc,
      price: menuItem.price,
      active: menuItem.active,
      wrapping: menuItem.wrapping
    }
  });

  useEffect(() => {
    getMenuItem(menuItemId);
  }, [])

  useEffect(() => {
    menuItem
      ?
      setChoosenImage(menuItem.image)
      :
      null
  }, [menuItem]);

  useEffect(() => {
    reset(menuItem);
  }, [menuItem]);

  const handleImageModal = () => {
    toggleModal();
  }

  const handleImageChange = (img) => {
    setChoosenImage(img.name);
    toggleModal();
  }

  const checkImageError = () => {
    if (choosenImage === undefined) {
      setImageError(true);
    } else {
      setImageError(false);
    }
  }

  const onSubmit = data => {
    if (choosenImage === undefined) {
      setImageError(true);
    } else {
      setImageError(false);
      updateMenuItem(menuItem._id, data.name, data.desc, data.price, data.active, data.wrapping, choosenImage.name);
    }
  }

  return (
    <>
      {!getMenuItemIsLoading && Object.keys(menuItem).length !== 0
        ?
        (
          <>
            <StyledFormHeader>Edytuj Produkt</StyledFormHeader>
            <button type="button" onClick={handleImageModal}>
              {choosenImage
                ?
                <AddImage src={menuItem.image ? `${process.env.REACT_APP_API}/upload/${choosenImage}` : addImage} alt="Wybierz Zdjęcie" />
                :
                <Loading />
              }
            </button>
            {imageError ? <span>OBRAZ JEST WYMAGANY</span> : null}
            <StyledForm onSubmit={handleSubmit(onSubmit, checkImageError)}>
              <InputField>
                <StyledInput
                  name="name"
                  placeholder="Nazwa produktu"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Nazwa jest wymagana'
                    }
                  })}
                />
                <span>{errors.name && <StyledAlertIcon />}</span>
                <div>{errors.name && errors.name.message}</div>
              </InputField>
              <InputField>
                <StyledInput
                  name="desc"
                  placeholder="Opis Produktu"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Opis Produktu jest wymagany'
                    }
                  })}
                />
                <span>{errors.name && <StyledAlertIcon />}</span>
                <div>{errors.name && errors.name.message}</div>
              </InputField>
              <InputField>
                <StyledInput
                  type="number"
                  step="0.01"
                  name="price"
                  placeholder="Cena"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Cena jest wymagana'
                    }
                  })}
                />
                <span>{errors.name && <StyledAlertIcon />}</span>
                <div>{errors.name && errors.name.message}</div>
              </InputField>
              <InputField>
                <label htmlFor="active">
                  <input
                    style={{ marginRight: '10px' }}
                    ref={register({})}
                    name="active"
                    id="active"
                    type="checkbox"
                  />
                  Produkt dostępny
                </label>
              </InputField>
              <InputField>
                <label htmlFor="wrapping">
                  <input
                    style={{ marginRight: '10px' }}
                    ref={register({})}
                    name="wrapping"
                    id="wrapping"
                    type="checkbox"
                  />
                  Dolicz cenę opakowania
                </label>
              </InputField>
              <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
              <StyledErrorBox err={updateMenuItemErrorResponse}><AlertTriangle />{updateMenuItemErrorResponse}</StyledErrorBox>
              <StyledInput type='submit' value='Zaktualizuj Kategorię' />
              {getMenuItemError ? (<div>{getMenuItemError}</div>) : null}
            </StyledForm>
          </>
        )
        :
        <Loading />
      }
      <Modal
        show={isShowingModal}
        clicked={toggleModal}
      >
        <MediaPicker handleImageChange={handleImageChange} />
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  errorResponse: state.restaurants.error,
  getMenuItemError: state.items.getMenuItemError,
  getMenuItemIsLoading: state.items.getMenuItemIsLoading,
  getMenuItem: state.items.getMenuItem,
  menuItem: state.items.menuItem,
  updateMenuItemErrorResponse: state.items.updateMenuItemErrorResponse
});

const mapDispatchToProps = dispatch => ({
  updateMenuItem: (id, name, desc, price, active, wrapping, image) => dispatch(actions.updateMenuItem(id, name, desc, price, active, wrapping, image)),
  getMenuItem: (menuItemId) => dispatch(actions.getMenuItem(menuItemId))
});

EditMenuItemForm.propTypes = {
  menuItem: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    desc: PropTypes.string,
    price: PropTypes.number,
    active: PropTypes.bool,
    wrapping: PropTypes.bool,
    image: PropTypes.string
  }),
  updateMenuItem: PropTypes.func.isRequired,
  getMenuItem: PropTypes.func.isRequired,
  getMenuItemIsLoading: PropTypes.bool.isRequired,
  getMenuItemError: PropTypes.string,
  errorResponse: PropTypes.string,
  updateMenuItemErrorResponse: PropTypes.string,
  menuItemId: PropTypes.string.isRequired
};

EditMenuItemForm.defaultProps = {
  menuItem: {},
  errorResponse: null,
  getMenuItemError: null,
  updateMenuItemErrorResponse: null
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMenuItemForm);
