import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledStatusTag = styled.div`
  background-color:  ${({ color }) => color};
  display: flex;
  justify-content: center;
  font-size: 9px;
  font-weight: 600;
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  text-align: center;
`;

const StatusTag = (props) => {
  const { status, className } = props;

  const realizationStatus = {
    'awaitingPayment': {
      name: 'Płatność...',
      color: 'rose'
    },
    'new': {
      name: 'Przyjęte',
      color: '#15803d'
    },
    'confirmed': {
      name: 'Przyjęte',
      color: '#22c55e'
    },
    'preparing': {
      name: 'Przygotowanie',
      color: '#9333ea'
    },
    'delivery': {
      name: 'Dostawa',
      color: '#3b82f6'
    },
    'ready': {
      name: 'Gotowe',
      color: '#f59e0b'
    },
    'planned': {
      name: 'Zaplanowane',
      color: '#b45309'
    },
    'completed': {
      name: 'Zrealizowane',
      color: '#71717a'
    },
    'canceled': {
      name: 'Anulowane',
      color: '#ef4444'
    },
    'failedPayment': {
      name: 'Nieudana płatność',
      color: '#334155'
    },
  }

  return (
    <StyledStatusTag color={realizationStatus[status].color} className={className}>
      {realizationStatus[status].name}
    </StyledStatusTag>
  );
};

StatusTag.propTypes = ({
  status: PropTypes.string.isRequired,
  className: PropTypes.string
});

StatusTag.defaultProps = ({
  className: null
})

export default StatusTag;
