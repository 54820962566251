import axios from 'axios';
import * as actionTypes from './actionTypes';

// get Item Options
export const getItemOptionsStart = () => ({
  type: actionTypes.GET_ITEM_OPTIONS_START
});

export const getItemOptionsSuccess = (data) => ({
  type: actionTypes.GET_ITEM_OPTIONS_SUCCESS,
  data: data
});

export const getItemOptionsFail = (error) => ({
  type: actionTypes.GET_ITEM_OPTIONS_FAIL,
  error: error
});

export const getItemOptions = (restaurantId) => dispatch => {
  dispatch(getItemOptionsStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/admin/itemOptions/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getItemOptionsSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getItemOptionsFail(err.response.data.message));
    })
};

// add item Option
export const addItemOptionStart = () => ({
  type: actionTypes.ADD_ITEM_OPTION_START
});

export const addItemOptionSuccess = (data) => ({
  type: actionTypes.ADD_ITEM_OPTION_SUCCESS,
  data: data
});

export const addItemOptionFail = (error) => ({
  type: actionTypes.ADD_ITEM_OPTION_FAIL,
  error: error
});

export const addItemOption = (name, price, itemOptionsGroupId, restaurantId) => dispatch => {
  dispatch(addItemOptionStart());

  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/admin/itemOption`, {
    name: name,
    price: price,
    itemOptionsGroupId: itemOptionsGroupId,
    restaurantId: restaurantId,
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(addItemOptionSuccess(res.data));
    })
    .catch(err => {
      dispatch(addItemOptionFail(err.response.data.message));
    })
};

// update Item Option
export const updateItemOptionStart = () => ({
  type: actionTypes.UPDATE_ITEM_OPTION_START
});

export const updateItemOptionSuccess = (data) => ({
  type: actionTypes.UPDATE_ITEM_OPTION_SUCCESS,
  data: data
});

export const updateItemOptionFail = (error) => ({
  type: actionTypes.UPDATE_ITEM_OPTION_FAIL,
  error: error
});

export const updateItemOption = (itemOptionId, name, price) => dispatch => {
  dispatch(updateItemOptionStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/admin/itemOption/${itemOptionId}`, {
    name: name,
    price: price
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(updateItemOptionSuccess(res.data.itemOption));
    })
    .catch(err => {
      dispatch(updateItemOptionFail(err.response.data.message));
    })
};

// delete Item Option
export const deleteItemOptionStart = () => ({
  type: actionTypes.DELETE_ITEM_OPTION_START
});

export const deleteItemOptionSuccess = (data) => ({
  type: actionTypes.DELETE_ITEM_OPTION_SUCCESS,
  data: data
});

export const deleteItemOptionFail = (error) => ({
  type: actionTypes.DELETE_ITEM_OPTION_FAIL,
  error: error
});

export const deleteItemOption = (itemOptionId) => dispatch => {
  dispatch(deleteItemOptionStart());

  const token = localStorage.getItem('token');
  axios.delete(`${process.env.REACT_APP_API}/admin/itemOption/${itemOptionId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(deleteItemOptionSuccess(res.data.itemOptionId));
    })
    .catch(err => {
      dispatch(deleteItemOptionFail(err.response.data.message));
    })
};
