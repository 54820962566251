import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as actions from '../../../state/actions/index';
import Button from '../../UI/Button/Button';

const OrderStatusManagementContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 10px 10px;
  border-radius: 5px;
`;

const StatusConfirmedButton = styled(Button)`
  && {
    background-color: #15803d;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }
`;

const StatusPreparingButton = styled(Button)`
  && {
    background-color: #9333ea;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }
`;

const StatusDeliveryButton = styled(Button)`
  && {
    background-color: #3b82f6;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }
`;

const StatusReadyButton = styled(Button)`
  && {
    background-color: #f59e0b;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }
`;

const StatusPlannedButton = styled(Button)`
  && {
    background-color: #b45309;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }
`;

const StatusCompletedButton = styled(Button)`
  && {
    background-color: #71717a;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }
`;

const StatusCanceledButton = styled(Button)`
  && {
    background-color: #ef4444;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }
`;

const OrderStatusManagement = (props) => {
  const { updateOrderStatus, order } = props;
  const { _id, status, realizationType, realizationTimeOption } = order;
  const orderStatusHandler = (orderUpdateId, newStatus) => {
    status !== newStatus ? updateOrderStatus(orderUpdateId, newStatus) : null;
  }

  return (
    <OrderStatusManagementContainer>
      {
        realizationTimeOption === 'planned'
          ?
          <StatusPlannedButton clicked={() => orderStatusHandler(_id, 'planned')}>Zaplanowane</StatusPlannedButton>
          :
          <StatusConfirmedButton clicked={() => orderStatusHandler(_id, 'confirmed')}>Przyjęte</StatusConfirmedButton>
      }
      <StatusPreparingButton clicked={() => orderStatusHandler(_id, 'preparing')}>Przygotowanie</StatusPreparingButton>
      {
        realizationType === 'delivery'
          ?
          <StatusDeliveryButton clicked={() => orderStatusHandler(_id, 'delivery')}>W drodze</StatusDeliveryButton>
          :
          <StatusReadyButton clicked={() => orderStatusHandler(_id, 'ready')}>Gotowe</StatusReadyButton>
      }
      <StatusCompletedButton clicked={() => orderStatusHandler(_id, 'completed')}>Zrealizowane</StatusCompletedButton>
      <StatusCanceledButton clicked={() => orderStatusHandler(_id, 'canceled')}>Anulowane</StatusCanceledButton>
    </OrderStatusManagementContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  updateOrderStatus: (objectId, status) => dispatch(actions.updateOrderStatus(objectId, status))
});

OrderStatusManagement.propTypes = {
  updateOrderStatus: PropTypes.func.isRequired,
  order: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    realizationType: PropTypes.string,
    realizationTimeOption: PropTypes.string
  }).isRequired,
}

export default connect(null, mapDispatchToProps)(OrderStatusManagement);
