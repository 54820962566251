import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trash2, Edit, ChevronRight, MoreVertical } from 'react-feather';
import Button from '../../../../components/UI/Button/Button';

const ListItem = styled.div`
  display: flex;
  border-radius: 10px;
  width: 100%;
  padding: 10px 20px;
  flex-direction: column;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  overflow: hidden;
  touch-action: none;
  opacity: 0.4;
`;

const ListItemHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ListItemData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ListItemManage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListItemTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const ListItemImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 20px;
  margin-left: 20px;
`;

const ToggleIcon = styled(ChevronRight)`
  transition: transform .2s ease-in-out;
  transform: ${props => props.$itemcategorytoggled ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

// eslint-disable-next-line react/display-name
const Category = forwardRef(({ id, name, image }, ref) => (
  <ListItem ref={ref}>
    <ListItemHeading>
      <ListItemData>
        <Button paddingX={5} marginX={5}>
          <MoreVertical size={16} />
        </Button>
        <Button paddingX={10}>
          <ToggleIcon size={20} />
        </Button>
        <ListItemImg key={id} src={`${process.env.REACT_APP_API}/upload/${image}`} alt={name} />
        <ListItemTitle> {name} </ListItemTitle>
      </ListItemData>
      <ListItemManage>
        <Button>
          <Edit size={20} />
        </Button>
        <Button variant="alert">
          <Trash2 size={20} />
        </Button>
      </ListItemManage>
    </ListItemHeading>
  </ListItem>
)
);

Category.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
}


export default Category;
