import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Trash2, Edit, PlusCircle } from 'react-feather';
import * as actions from '../../../../state/actions/index';
import ConfirmationModal from '../../../../components/UI/ConfirmationModal/ConfirmationModal';
import useConfirmationModal from '../../../../hooks/UseConfirmationModal/useConfirmationModal';
import Modal from '../../../../components/UI/Modal/Modal';
import useModal from '../../../../hooks/useModal/useModal';
import Button from '../../../../components/UI/Button/Button';

import AddItemOptionForm from '../../../../components/Forms/AddItemOptionForm/AddItemOptionForm';
import EditItemOptionForm from '../../../../components/Forms/EditItemOptionForm/EditItemOptionForm';

const ItemOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemOptionsListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
`;

const ItemOptionsListItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  margin-bottom: 10px;
  padding: 10px;
  gap: 30px;
  align-items: center;
  touch-action: none;
`;

const ItemOptionsListItemData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 15px;
`;

const ItemOptionsListItemName = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

const ItemOptionsListItemPrice = styled.span`
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
`;

const ItemOptionsListItemManage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlusCircleIcon = styled(PlusCircle)`
  margin-right: 8px;
`;

const ItemOptionsList = (props) => {
  const {
    itemOptionsGroupId,
    itemOptions,
    deleteItemOption
  } = props;

  const [isShowingConfirmationModal, toggleConfirmationModal] = useConfirmationModal();
  const [flteredItemsOptions, setFilteredItemsOptions] = useState(() => itemOptions.filter((item) => item.itemOptionsGroup === itemOptionsGroupId ? item : null));
  const [isShowingAddItemModal, toggleAddItemModal] = useModal();
  const [isShowingEditItemModal, toggleEditItemModal] = useModal();
  const [itemOptionId, setItemOptionId] = useState();
  const [itemOption, setItemOption] = useState();

  useEffect(() => {
    setFilteredItemsOptions(() => itemOptions.filter((item) =>
      item.itemOptionsGroup === itemOptionsGroupId ? item : null
    ))
  }, [itemOptions])

  const handleAddItemClicked = () => {
    toggleAddItemModal();
  }

  const handleEditItemClicked = (item) => {
    toggleEditItemModal();
    setItemOption(item);
  }

  const handleDeleteItemClicked = (item) => {
    toggleConfirmationModal();
    setItemOptionId(item._id);
  }

  const handleConfirmDelete = (id) => {
    deleteItemOption(id);
    toggleConfirmationModal();
  }

  return (
    <ItemOptionsContainer>
      <ItemOptionsListContainer>
        {
          flteredItemsOptions.length > 0
            ?
            (flteredItemsOptions.map(item => (
              <ItemOptionsListItemRow key={item._id}>
                <ItemOptionsListItemData>
                  <ItemOptionsListItemName>
                    {item.name}
                    <ItemOptionsListItemPrice>
                      +{item.price.toFixed(2)} zł
                    </ItemOptionsListItemPrice>
                  </ItemOptionsListItemName>
                  <ItemOptionsListItemManage>
                    <Button clicked={() => handleEditItemClicked(item)} paddingX={8} marginX={5}>
                      <Edit size={16} />
                    </Button>
                    <Button clicked={() => handleDeleteItemClicked(item)} variant="alert" paddingX={8} marginX={5}>
                      <Trash2 size={16} />
                    </Button>
                  </ItemOptionsListItemManage>
                </ItemOptionsListItemData>
              </ItemOptionsListItemRow>
            )))
            :
            <p>Brak opcji dla tego produktu</p>
        }
      </ItemOptionsListContainer>
      <div>
        <Button clicked={() => handleAddItemClicked()}> <PlusCircleIcon />Dodaj Opcję</Button>
      </div>
      <Modal
        show={isShowingAddItemModal}
        clicked={toggleAddItemModal}
      >
        <AddItemOptionForm itemOptionGroupId={itemOptionsGroupId} />
      </Modal>

      <Modal
        show={isShowingEditItemModal}
        clicked={toggleEditItemModal}
      >
        <EditItemOptionForm itemOption={itemOption} />
      </Modal>

      <ConfirmationModal
        show={isShowingConfirmationModal}
        clicked={toggleConfirmationModal}
        title="Potwierdź operację"
        text="Czy chcesz usunąć opcję?"
        onConfirm={() => handleConfirmDelete(itemOptionId)}
      />
    </ItemOptionsContainer>
  );
};

const mapStateToProps = state => ({
  itemOptions: state.itemOptions.itemOptions
});

const mapDispatchToProps = dispatch => ({
  deleteItemOption: (id) => dispatch(actions.deleteItemOption(id))
});

ItemOptionsList.propTypes = {
  deleteItemOption: PropTypes.func.isRequired,
  itemOptionsGroupId: PropTypes.string.isRequired,
  itemOptions: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number
  }))
}

ItemOptionsList.defaultProps = {
  itemOptions: [],
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemOptionsList);
