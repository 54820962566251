import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioInput = styled.input.attrs({
  type: "radio",
})`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  & + img {
    cursor: pointer;
  }

  &:hover + img:hover {
    outline: 2px solid red;
  }

  &:checked + img:checked {
    outline: 2px solid red;
  }
`;

const ImgList = styled.div`
  display: flex;
  flex-wrap: wrap;

  & label{
    width: 30%;
    padding: 5px;
  }
`;

const AddImage = styled.img`
  max-width: 70px;
  max-height: 70px;
  border-radius:50%;
  cursor: pointer;
`;


const MediaPicker = (props) => {
  const {
    images,
    handleImageChange
  } = props;

  return (
    <ImgList>
      {images.length > 0 ?
        images.map(image =>
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label htmlFor={image._id} key={image._id}>
            <RadioInput type="radio" id={image._id} name="image" value={image._id} onChange={() => handleImageChange(image)} />
            <AddImage src={`${process.env.REACT_APP_API}/upload/${image.name}`} alt={image.name} />
          </label>
        )
        :
        <p>Brak Obrazów</p>
      }
    </ImgList>
  );
};

const mapStateToProps = state => ({
  images: state.images.images
})

MediaPicker.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  })),
  handleImageChange: PropTypes.func.isRequired
};

MediaPicker.defaultProps = {
  images: [],
}

export default connect(mapStateToProps, null)(MediaPicker);
