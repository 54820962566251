import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  isLoading: false,
  itemOptions: [],
  error: null,
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ITEM_OPTIONS_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.GET_ITEM_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        itemOptions: action.data,
        error: null
      }
    case actionTypes.GET_ITEM_OPTIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.ADD_ITEM_OPTION_START:
      return {
        ...state
      }
    case actionTypes.ADD_ITEM_OPTION_SUCCESS:
      return {
        ...state,
        itemOptions: [...state.itemOptions, action.data.itemOption]
      }
    case actionTypes.ADD_ITEM_OPTION_FAIL:
      return {
        ...state,
        error: action.error
      }
    case actionTypes.DELETE_ITEM_OPTION_START:
      return {
        ...state
      }
    case actionTypes.DELETE_ITEM_OPTION_SUCCESS:
      return {
        ...state,
        itemOptions: state.itemOptions.filter(itemOptions =>
          itemOptions._id !== action.data
        )
      }
    case actionTypes.DELETE_ITEM_OPTION_FAIL:
      return {
        ...state,
        error: action.error
      }
    case actionTypes.UPDATE_ITEM_OPTION_START:
      return {
        ...state,
      }
    case actionTypes.UPDATE_ITEM_OPTION_SUCCESS:
      return {
        ...state,
        itemOptions: state.itemOptions.map(itemOption =>
          itemOption._id === action.data._id ? action.data : itemOption)
      }
    case actionTypes.UPDATE_ITEM_OPTION_FAIL:
      return {
        ...state,
        error: action.error
      }
    default:
      return state;
  }
};

export default reducer;
