/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FileText } from 'react-feather';
import * as actions from '../../../state/actions/index';
import OrderPrint from './OrderPrint';
import OrderStatusManagement from './OrderStatusManagement';
import StatusTag from '../StatusTag';
import Loading from '../../UI/Loading/Loading';

const OrderDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const OrderDataInfo = styled.div`
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  border-radius: 5px;
`;

const OrderDataRow = styled.div`
  display: flex;
  gap:10px;
`;

const OrderDataCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  width: 50%;
  gap: 10px;
`;

const OrderDataUser = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 10px 10px;
  border-radius: 5px;
`;

const OrderDataPayment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 10px 10px;
  border-radius: 5px;
`;

const OrderDataItems = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 10px 10px;
  border-radius: 5px;
`;

const OrderDataStatusPrint = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 10px 10px;
  border-radius: 5px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ col }) => col ? 'column' : 'row'};
  padding: 8px 10px;
  background-color: white;
  border-radius: 5px;
  font-weight: 600;
`;

const RowTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
`;

const RowData = styled.div`
font-size: 13px;
`;

const BigStatusTag = styled(StatusTag)`
  && {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 15px;
  }
`;

const OrderDataInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

const OrderDataInfoTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
`;

const OrderDataInfoText = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #152839;
`;

const PickOrder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  color: #cbd5e1;

  & h2 {
    color: #cbd5e1;
  }
`;

const CartItemOptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 5px 0;
`;

const CartItemOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const CartItemOptionData = styled.div`
  font-size: 12px;
  font-weight: 500;

  & span {
    color: #152839;
    font-weight: 800;
  }
`;

const CartItemDataQuantity = styled.div`
  margin-right: 10px;

  & p{
    color: #152839;
    font-weight: 800;
    font-size: 14px;
  }
`;

const CartItemDataName = styled.div`
  & p{
    color: #152839;
    font-weight: 800;
    font-size: 14px;
  }
`;

const CartItemDataPrice = styled.div`

  & p{
    font-weight: 500;
    font-size: 14px;
  }
`;

const StyledCardItems = styled.div`
  width: 100%;

  & div:last-child {
      border-bottom: none;
    }
`;

const StyledCartItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border-bottom: 1px #a6a6a6 solid;
`;

const CartItemDataCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const CartItemData = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const CartItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

const PriceContainer = styled.div`
  display: flex;
`;

const PriceSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 10px;
  width: 100%;

  & div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.final{
      font-weight: 800;
      color: #152839;
    }
  }

    @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const OrderData = (props) => {
  const { activeOrder, getCart, cart, isLoadingCart } = props;

  const [sortedProducts, setSortedProducts] = useState([]);

  function compareOptions(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false
    }

    const result = [];

    for (let i = 0; i < arr1.length; i++) {
      if (
        arr1[i].optionGroup._id !== arr2[i].optionGroup._id
        ||
        arr1[i].option._id !== arr2[i].option._id
      ) {
        result.push(false);
        // eslint-disable-next-line no-else-return
      } else if (
        arr1[i].optionGroup._id === arr2[i].optionGroup._id
        &&
        arr1[i].option._id === arr2[i].option._id
      ) {
        result.push(true);
      }
    }
    if(result.filter(item => item === true ? item : null).length === arr1.length){
      return true;
    }
    return false;
  }

  useEffect(() => {
    if(activeOrder.cart){
      getCart(activeOrder.cart, localStorage.getItem('activeRestaurant'));
    }
  }, [])

  useEffect(() => {
    if(cart.products){
    const copyProducts = cart.products.reduce((acc, curr) => {
      if (acc.filter(item => item.product._id === curr.product._id).length === 0) {
        const currCopy = { ...curr };
        currCopy.quantity = 1;
        acc.push(currCopy);
        return acc;
      }
      if (acc.filter(item => item.product._id === curr.product._id).length > 0) {
        const accItem = acc.filter(item => item.product._id === curr.product._id && compareOptions(item.options, curr.options))[0];
        if (accItem && compareOptions(accItem.options, curr.options)) {
          const accCopy = [...acc];
          const duplicateProductIndex = accCopy.findIndex(item => item.product._id === curr.product._id && compareOptions(item.options, curr.options));
          accCopy[duplicateProductIndex].quantity += 1;
          return (accCopy);
        }
        const currCopy = { ...curr };
        currCopy.quantity = 1;
        acc.push(currCopy);
        return acc;
      }
      const accCopy = [...acc];
      const duplicateProductIndex = accCopy.findIndex(item => item.product._id === curr.product._id);
      accCopy[duplicateProductIndex].quantity += 1;
      return (accCopy);
    }, []);
    setSortedProducts(copyProducts);
  }
  }, [cart])


  const CartData = (
    sortedProducts.length > 0
      ?
      (<>
        <StyledCardItems>
          {sortedProducts.map(item => (

            <StyledCartItem key={item._id}>
              <CartItemRow>
                <CartItemData>
                  <CartItemDataCol>
                    <CartItemDataQuantity><p>{item.quantity}x</p></CartItemDataQuantity>
                    <CartItemDataName><p>{item.product.name}</p></CartItemDataName>
                  </CartItemDataCol>
                  <CartItemDataPrice><p>{item.product.price.toFixed(2)} zł</p></CartItemDataPrice>
                </CartItemData>
              </CartItemRow>
              <CartItemRow>
              {
                item.options.length > 0
                  ?
                  <CartItemOptionContainer>
                    {item.options.map((optionItem) => (
                      <CartItemOption key={optionItem.optionGroup._id}>
                        <CartItemOptionData>
                          {optionItem.optionGroup.name}:
                          <span> {optionItem.option.name}</span>
                        </CartItemOptionData>
                      </CartItemOption>
                    ))}
                  </CartItemOptionContainer>
                  :
                  null
              }
              </CartItemRow>
            </StyledCartItem>
          ))}
        </StyledCardItems>
        <PriceContainer>
          <PriceSummary>
            <div>
              <div>Zamówienie: </div>
              <div>{cart.productPrice.toFixed(2)} zł</div>
            </div>
            <div>
              <div>Opakowanie: </div>
              <div>{cart.wrappingPrice.toFixed(2)} zł</div>
            </div>
            {
              activeOrder.realizationType === 'delivery'
                ?
                <div>
                  <div>Dostawa: </div>
                  <div>{activeOrder.deliveryData.deliveryPrice === false ? 'obszar poza strefą dostaw' : `${activeOrder.deliveryData.deliveryPrice.toFixed(2)} zł`}</div>
                </div>
                :
                null
            }
            <div className="final">
              <div>Do zapłaty: </div>
              {
                activeOrder.realizationType === 'delivery'
                ?
                (<div>{(cart.fullPrice + activeOrder.deliveryData.deliveryPrice).toFixed(2)} zł</div>)
                :
                (<div>{(cart.fullPrice).toFixed(2)} zł</div>)
              }
            </div>
          </PriceSummary>
        </PriceContainer>
      </>
      )
      :
      (
        <p>Nothing here :(</p>
      )
  );

  if (Object.keys(activeOrder).length === 0) {
    return (
      <PickOrder>
        <FileText size={80} />
        <h2>Wybierz zamówienie</h2>
      </PickOrder>
    );
  }
  return (
    <OrderDataContainer>
      <OrderDataInfo>
        <OrderDataInfoCol>
          <OrderDataInfoTitle>Numer zamówienia:</OrderDataInfoTitle>
          <OrderDataInfoText># {activeOrder.orderNumber}</OrderDataInfoText>
        </OrderDataInfoCol>
        <OrderDataInfoCol>
          <OrderDataInfoTitle>Data:</OrderDataInfoTitle>
          <OrderDataInfoText>
          {`${new Date(activeOrder.orderDate).getDate()}/${new Date(activeOrder.orderDate).getMonth()+1}/${new Date(activeOrder.orderDate).getFullYear()}`}
          </OrderDataInfoText>
        </OrderDataInfoCol>
        <OrderDataInfoCol>
          <OrderDataInfoTitle>Godzina:</OrderDataInfoTitle>
          <OrderDataInfoText>{`${new Date(activeOrder.orderDate).getHours()}:${new Date(activeOrder.orderDate).getMinutes() < 10 ? (`0${  new Date(activeOrder.orderDate).getMinutes()}`) : new Date(activeOrder.orderDate).getMinutes()}`}</OrderDataInfoText>
        </OrderDataInfoCol>
        <BigStatusTag status={activeOrder.status} />
      </OrderDataInfo>
      <OrderDataRow>
        <OrderDataCol>
          <OrderDataUser>
            <Row>
              <RowTitle>Imię i nazwisko:</RowTitle>
              <RowData>{activeOrder.userData.name} {activeOrder.userData.surname}</RowData>
            </Row>
            <Row>
              <RowTitle>Email:</RowTitle>
              <RowData>{activeOrder.userData.email}</RowData>
            </Row>
            <Row>
              <RowTitle>Telefon:</RowTitle>
              <RowData>{activeOrder.userData.phone}</RowData>
            </Row>
            {/* zarejestrowany użytkownik? */}
          </OrderDataUser>
          <OrderDataPayment>
            <Row>
              <RowTitle>Realizacja:</RowTitle>
              <RowData>{activeOrder.realizationType === 'delivery' ? 'Dostawa' : 'Odbiór osobisty'}</RowData>
            </Row>
            <Row>
              <RowTitle>Płatność:</RowTitle>
              <RowData>{activeOrder.payment.paymentTypeName}</RowData>
            </Row>
            <Row>
              <RowTitle>Zamówienie opłacone:</RowTitle>
              <RowData>{activeOrder.payment.status === false ? 'Nie' : 'Tak'}</RowData>
            </Row>
            {activeOrder.realizationType === 'delivery' ?
            (
            <>
            <Row>
              <RowTitle>Miejscowość:</RowTitle>
              <RowData>{activeOrder.deliveryData.locality}</RowData>
            </Row>
            <Row>
              <RowTitle>Adres:</RowTitle>
              <RowData>{activeOrder.deliveryData.street} {activeOrder.deliveryData.buildingNumber}</RowData>
            </Row>
            <Row>
              <RowTitle>Numer mieszkania:</RowTitle>
              <RowData>{activeOrder.deliveryData.localNumber ? activeOrder.deliveryData.localNumber : null}</RowData>
            </Row>
            <Row>
              <RowTitle>Odległość:</RowTitle>
              <RowData>{activeOrder.deliveryData.distance/1000} km</RowData>
            </Row>
            </>)
              :
              null
            }
            <Row>
              <RowTitle>Sztućce, sól, serwetki:</RowTitle>
              <RowData>{activeOrder.other.cutlery ? 'Tak' : 'Nie'}</RowData>
            </Row>
            <Row col>
              <RowTitle>Informacje Dodatkowe:</RowTitle>
              <RowData>{activeOrder.other.additionalInfo ? activeOrder.other.additionalInfo : null}</RowData>
            </Row>
          </OrderDataPayment>
          <OrderDataStatusPrint>
            {
              sortedProducts.length > 0
              ?
              <OrderPrint order={activeOrder} cart={sortedProducts}/>
              :
              null
            }
          </OrderDataStatusPrint>
        </OrderDataCol>
        <OrderDataCol>
          <OrderDataItems>
            <h3>Zamówienie</h3>
          {
            isLoadingCart
              ?
              <Loading />
              :
              CartData
          }
          </OrderDataItems>
          <OrderStatusManagement
            order={activeOrder}
          />
        </OrderDataCol>
      </OrderDataRow>
    </OrderDataContainer>
  )
};


const mapStateToProps = state => ({
  isLoadingCart: state.orders.isLoadingCart,
  cart: state.orders.cart,
});

const mapDispatchToProps = dispatch => ({
  getCart: (cartId, restaurantId) => dispatch(actions.getCart(cartId, restaurantId))
})

OrderData.propTypes = {
  isLoadingCart: PropTypes.bool.isRequired,
  cart: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({
      product: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        desc: PropTypes.string,
        image: PropTypes.string,
        price: PropTypes.number
      }),
    })),
    productPrice: PropTypes.number,
    wrappingPrice: PropTypes.number,
    fullPrice: PropTypes.number,
  }),
  getCart: PropTypes.func.isRequired
}

OrderData.defaultProps = {
  cart: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderData);
